.hamburger-icon--nav-wrap {
    display: flex;

    .hamburger-icon--container {
        display: inline-block;
        cursor: pointer;
    
        .hamburger-icon--bar1, .hamburger-icon--bar2, .hamburger-icon--bar3 {
            width: 35px;
            height: 5px;
            background-color: black;
            margin: 6px 0;
            transition: 0.4s;
        }
    }
}

.toggled .hamburger-icon--bar1 {
    transform: translate(0, 11px) rotate(-45deg);
}
  
.toggled .hamburger-icon--bar2 {
    opacity: 0;
}
  
.toggled .hamburger-icon--bar3 {
transform: translate(0, -11px) rotate(45deg);
}

.hamburger-icon--nav-list{
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(8px);
    box-shadow: 2px 2px 2px 1px rgba(75, 82, 247, 0.2);
    // box-shadow: 1px 5px 8px gray;
    border-radius: 0 0 30px 12px / 0 0 30px 12px;
    height: 0px;
    top: 100%;
    right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    visibility: hidden;
    transition: 0.3s ease;
    overflow: hidden;
    &::after{
        content: '';

    }
    .hamburger-icon--nav-item{
        position: relative;
        color: #fff;
        cursor: pointer;

        p {
            position: relative;
            display: inline;
            &::after{
                position: absolute;
                display: block;
                content: '';
                height: 0;
                width: 0;
                background: #fff;
                transition: all 0.5s;
            }
        } 
        // &hover,&::after{
        //     position: absolute;
        //     display: block;
        //     content: '';
        //     height: 0;
        //     width: 0;
        //     background: red;
        // }
        &:hover{
            color: rgb(0, 152, 255);
            p {
                &::after{
                    width: 100%;
                    height: 1px;
                }
            } 
        }
    }
}

.toggled .hamburger-icon--nav-list{
    height: 250px;
    padding: 20px;
    visibility:visible;
}
