.footer-section--wrap{
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    font-family: 'Poppins', sans-serif;

    .footer-section--item-container{
        margin-top: 10px;
        margin-bottom: 20px;
        
        .footer-section--item-header{
            font-size: 1rem;
        }

        .footer-section--item-body-outerCtn {
            width: 300px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: row;
            margin-top: 10px;

            .footer-section--item-body {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;

                .footer-section--item-body-one {
                    height: 25px;
                    width: 25px;
                    
                    .footer-section-img {
                        height: 25px;
                    }
                }
    
                .footer-section--item-body-two {
    
                }
            }
        }

        .footer-section--item-body-outerCtnn {
            width: auto;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;
            flex-direction: column;
        
            .footer-section--item-body {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;

                .footer-section--item-body-one {
                    height: 40px;
                    width: 25px;
                    margin-right: 15px;
                    
                    .footer-section-img {
                        height: 100%;
                        width: 100%;
                    }
                }
    
                .footer-section--item-body-two {
    
                }
            }
        }
    }
}

.footer-section--bg-overlay{
    padding: 100px 0;
    background-color: #fff;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
    .footer-section--bg-overlay {
        padding: 30px 0;
        .footer-section--wrap {
            flex-direction: column-reverse;

            .footer-section--item-container {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .footer-section--item-header {
                    font-size: 0.8rem;
                }

                .footer-section--item-body-outerCtnn {
                    width: 70%;
                    .footer-section--item-body {
                        .footer-section--item-body-one {
                            .footer-section-img {

                            }
                        }

                        .footer-section--item-body-two {
                            font-size: 0.6rem;
                            text-align: start;
                        }
                    }
                }

                .footer-section--item-body-outerCtn {

                    .footer-section--item-body {
                        .footer-section--item-body-one {
                            .footer-section-img {

                            }
                        }

                        .footer-section--item-body-two {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }

}

@media only screen and (min-width: 500px) and (max-width: 1200px) {
    .footer-section--bg-overlay {
        padding: 30px 0;
        .footer-section--wrap {
            flex-direction: column-reverse;

            .footer-section--item-container {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .footer-section--item-header {
                    font-size: 1rem;
                }

                .footer-section--item-body-outerCtnn {
                    width: 70%;
                    .footer-section--item-body {
                        .footer-section--item-body-one {
                            .footer-section-img {

                            }
                        }

                        .footer-section--item-body-two {
                            font-size: 0.8rem;
                            text-align: start;
                        }
                    }
                }

                .footer-section--item-body-outerCtn {

                    .footer-section--item-body {
                        .footer-section--item-body-one {
                            .footer-section-img {

                            }
                        }

                        .footer-section--item-body-two {
                            font-size: 0.1rem;
                        }
                    }
                }
            }
        }
    }

}