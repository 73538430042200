.features-list--section-wrap{
    margin-left: auto;
    margin-right: auto;
    color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    .features-list--list-ul{
        margin-top: 20px;
        width: 90%;
        .features-list--list-li{
            width: 100%;
            padding: 30px 0;
            border-bottom: 2px solid #eee;
            &:hover{
                background-color: #3b0063;
                color: #fff;
                stroke: #fff;
                transition: all 0.5s ease;                            
                .features-list--data-svg{
                    path{
                    transition: all 0.5s ease;
                        stroke: #FFFFFF;
                    }
                }   
            }

            .features-list--h6-wrap{
                .features-list--content-wrap{
                    display: block;
                    width: 90%;
                    margin-left: auto;
                    margin-right: auto;
                    .features-list--icon-wrap{
                        font-family: 'Poppins-Bold';
                        font-size: 20px;
                        display: flex;
                        .features-list--svg-wrap{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: flex-start;
                            width: 60px;
                            height: 80px;
                            .features-list--data-svg{
                                height: 30px;
                                width: 30px;
                            }
                        }
                        .features-list--svg-title{
                            display: flex;
                            align-items: center;
                        }
                    }
                    .features-list--svg-content{
                        font-family: 'Poppins-Medium';
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.features-list--content-wrap-UpperNav {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .features-list--list-ul {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 40px;
        
        .features-list--list-li {
            height: 170px;
            width: 200px;

            .features-list--h6-wrap {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .features-list--content-wrap {
                    background: rgba(#9900ff, 0.6);
                    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
                    backdrop-filter: blur(0px);
                    border-radius: 3%;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    transition: all 0.9s;
                    &:hover {
                        background: rgba(#9900ff, 0.2);
                    }

                    .features-list--icon-wrap {
                        height: 100px;
                        width: 100%;
                        display: flex;
                        padding: 0 10px 0 10px;
                        justify-content: flex-start;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;

                        .features-list--svg-wrap {
                            .features-list--data-svg {
                                height: 50px;
                                width: 50px;
                                margin-bottom: 5px;
                            }
                        }

                        .features-list--svg-title {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

//Responsive Traits Screen
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .features-list--section-wrap{
        width: 90%;
    }
}


@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .features-list--section-wrap{
        width: 85%;
    }
}

@media only screen and (max-width: 991px) {
    .features-list--section-wrap{
        width: 90%;
    }
}

@media only screen and (max-width: 767px) {
    .features-list--section-wrap{
        width: 85%;
    }
}

@media only screen and (max-width: 575px) {
    .features-list--section-wrap{
        width: 100%;
    }
}

@media only screen and (min-width: 421px) and (max-width: 700px) {
    .features-list--content-wrap-UpperNav {
        .features-list--list-ul {
            .features-list--list-li {
                height: 80px;
                width: 100%;
                .features-list--h6-wrap {
                    .features-list--content-wrap {
                        border-radius: 8px;
                        .features-list--icon-wrap {
                            justify-content: flex-start;
                            align-items: center;
                            flex-direction: row;
                            .features-list--svg-wrap {
                                .features-list--data-svg {
                                    height: 40px;
                                    width: 40px;
                                    margin-right: 10px;                                    
                                }
                            }
                            .features-list--svg-title {
                                font-size: 0.9rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .features-list--section-wrap {
        .features-list--list-ul {
            width: 100%;
            .features-list--list-li {
                padding: 25px 15px;
                .features-list--h6-wrap {
                    .features-list--content-wrap {
                        width: 100%;
                        .features-list--svg-title {
                            font-size: 1.2rem;
                        }
                        .features-list--svg-content {
                            font-size: 0.75rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
    .features-list--content-wrap-UpperNav {
        .features-list--list-ul {
            .features-list--list-li {
                height: 60px;
                width: 100%;
                .features-list--h6-wrap {
                    .features-list--content-wrap {
                        border-radius: 8px;
                        .features-list--icon-wrap {
                            justify-content: flex-start;
                            align-items: center;
                            flex-direction: row;
                            .features-list--svg-wrap {
                                .features-list--data-svg {
                                    height: 25px;
                                    width: 25px;
                                    margin-right: 10px;                                    
                                }
                            }
                            .features-list--svg-title {
                                font-size: 0.7rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .features-list--section-wrap {
        .features-list--list-ul {
            width: 100%;
            .features-list--list-li {
                padding: 20px 15px;
                .features-list--h6-wrap {
                    .features-list--content-wrap {
                        width: 100%;
                        .features-list--svg-title {
                            font-size: 1.1rem;
                        }
                        .features-list--svg-content {
                            font-size: 0.7rem;
                        }
                    }
                }
            }
        }
    }
}