.website-portfolio--section-wrap{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 120px;
    opacity: 0;
    animation: faded 1s ease-in-out forwards;
    padding-top: 170px;

    #website-portfolio--header-section {
        height: auto;
        width: 90%;
        color: #282828;
        text-align: center;
        margin-bottom: 50px;
        #website-portfolio-featMsg {
            font-family: 'poppins', sans-serif;
            font-size: 1.5rem;
            opacity: 0.8;
            margin-bottom: 10px;
        }

        #website-portfolio-title {
            font-family: 'poppins-semibold', sans-serif;
            font-size: 3rem;
            span {
                color: #4DA4FC;
            }
        }

        #website-portfolio-desc {
            font-family: 'poppins', sans-serif;
            font-size: 1.2rem;
            opacity: 0.8;
        }
    }

    .website-portfolio--figure-section{
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        .website-portfolio--figure-wraps{
            position: relative;
            width: 500px;
            margin-top: 10px;
            overflow: hidden;
            border-radius: 10px;
            // background: rgba(255, 255, 255, 0.1);
            // backdrop-filter: blur(0px);
            // box-shadow: 2px 2px 2px 2px rgba(31, 38, 135, 0.1);
        
            .website-portfolio--figure-desc{
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                background: linear-gradient(90deg, #4DA4FC 0%, #CD65E3 100%);
                opacity: 0.8;
                background-size: 200% 100%;
                background-position: left top;
                color: #fff;
                z-index: 20;
                transform: translateX(-100%);
                transition: transform 0.5s;
                .website-portfolio--figure-title,
                .website-portfolio--figure-button{
                    transform: translate(650%, -1500%);
                    transition: transform 0.5s ease-in-out;
                }
                p, button{
                    display: inline-block;
                    font-family: 'Poppins-Bold', sans-serif;
                    font-size: 18px;
                    text-align: center;
                    a{
                        font-size: 14px;
                        font-family: 'Poppins', sans-serif;
                    }
                }
                .website-portfolio--figure-button{
                    position: absolute;
                    height: 40px;
                    width: 170px;
                    margin-top: 120px;
                    background-color: transparent;
                    border: 2px solid #fff;
                    border-radius: 10px;
                    &:hover{
                        background: #fff;
                        color: #000;
                    }
                }
            }
            .website-portfolio--figure-items{
                transition: all 1s;
                height: 400px;
                width: 100%;
            }
            &:hover { 
                .website-portfolio--figure-items{
                    transform: scale(1.12);
                }
                .website-portfolio--figure-desc{
                    transform: translateY(0%);
                    background-position: right top;
                    .website-portfolio--figure-title,
                    .website-portfolio--figure-button{
                        transform: translate(0%, 0%);
                    }
                }
            }
        }
    }
}

// container
.carousel-container {
    height: 600px;
    width: 100%;
}

.item-class {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.react-multi-carousel-item--active {
    transform: scale(1.3);
    transition: all 0.2s;
    z-index: 100;
}

@keyframes faded {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@media only screen and (min-width: 320px) and (max-width: 500px) {
    .website-portfolio--section-wrap {
        padding-top: 130px;

        #website-portfolio--header-section {
            width: 80%;
            margin-bottom: 0;
            #website-portfolio-featMsg {
                font-size: 0.8rem;
            }

            #website-portfolio-title {
                font-size: 1.2rem;
            }

            #website-portfolio-desc {

            }

        }

        .website-portfolio--figure-section {
            gap: 0;
            .website-portfolio--figure-wraps {
                height: 200px;
                width: 90%;
                .website-portfolio--figure-desc {
                    .website-portfolio--figure-title, .website-portfolio--figure-button {

                    }
                    
                    p, button {
                        font-size: 0.6rem;
                        a {
                            font-size: 0.6rem;
                            font-family: 'Poppins', sans-serif;
                        }
                    }
                    .website-portfolio--figure-button {
                        width: 120px;
                    }
                }
                .website-portfolio--figure-items {
                    height: 100%;
                }
            }
        }
    }
}

@media only screen and (min-width: 500px) and (max-width: 700px) {
    .website-portfolio--section-wrap {
        padding-top: 140px;

        #website-portfolio--header-section {
            width: 100%;
            #website-portfolio-featMsg {
                font-size: 1rem;
            }

            #website-portfolio-title {
                font-size: 1.5rem;
            }

            #website-portfolio-desc {

            }
        }

        .website-portfolio--figure-section {
            .website-portfolio--figure-wraps {
                height: 230px;
                width: 70%;
                .website-portfolio--figure-desc {
                    .website-portfolio--figure-title, .website-portfolio--figure-button {

                    }
                    
                    p, button {
                        font-size: 0.7rem;
                        a {
                            font-size: 0.7rem;
                            font-family: 'Poppins', sans-serif;
                        }
                    }
                    .website-portfolio--figure-button {
                        width: 120px;
                    }
                }
                .website-portfolio--figure-items {
                    height: 100%;
                }
            }
        }
    }
}

@media only screen and (min-width: 700px) and (max-width: 1199px) {
    .website-portfolio--section-wrap {
        padding-top: 170px;

        #website-portfolio--header-section {
            width: 100%;
            margin-bottom: 20px;
            #website-portfolio-featMsg {
                font-size: 1.2rem;
            }

            #website-portfolio-title {
                font-size: 1.9rem;
            }

            #website-portfolio-desc {

            }
        }

        .website-portfolio--figure-section {
            height: auto;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px;
            .website-portfolio--figure-wraps {
                height: auto;
                width: 300px;
                margin-top: 10px;
                .website-portfolio--figure-desc {
                    .website-portfolio--figure-title, .website-portfolio--figure-button {

                    }
                    
                    p, button {
                        font-size: 0.7rem;
                        a {
                            font-size: 0.7rem;
                            font-family: 'Poppins', sans-serif;
                        }
                    }
                    .website-portfolio--figure-button {
                        width: 120px;
                    }
                }
                .website-portfolio--figure-items {
                    height: 100%;
                }
            }
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .website-portfolio--section-wrap {
        padding-top: 150px;

        #website-portfolio--header-section {
            width: 100%;
            margin-bottom: 20px;
            #website-portfolio-featMsg {
                font-size: 1.2rem;
            }

            #website-portfolio-title {
                font-size: 2.2rem;
            }

            #website-portfolio-desc {

            }
        }

        .website-portfolio--figure-section {
            height: auto;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px;
            .website-portfolio--figure-wraps {
                height: auto;
                width: 330px;
                margin-top: 10px;
                .website-portfolio--figure-desc {
                    .website-portfolio--figure-title, .website-portfolio--figure-button {

                    }
                    
                    p, button {
                        font-size: 0.9rem;
                        a {
                            font-size: 0.9rem;
                            font-family: 'Poppins', sans-serif;
                        }
                    }
                    .website-portfolio--figure-button {
                        width: 120px;
                    }
                }
                .website-portfolio--figure-items {
                    height: 100%;
                }
            }
        }
    }
}