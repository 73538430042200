#highlightDetail-outer-container {
  height: auto;
  padding-top: 100px;

  #highlightDetail-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #highlightDetail-hero-container {
      height: 400px;
      width: 70%;
      font-family: 'Poppins', sans-serif;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
      padding: 100px 0 0 0;

      #highlightDetail-title {
        width: 70%;
        font-size: 3.5rem;
        font-family: 'Poppins-semibold', sans-serif;
        color: #282828;
        margin-bottom: 20px;
        line-height: 60px;
      }

      #highlightDetail-footerText {
        width: 60%;
        font-size: 1.1rem;
        font-family: 'Poppins', sans-serif;
        color: #282828;
      }
    }

    #highlightDetail-sample-img-container {
      height: auto;
      width: 100%;
      position: relative;
      padding: 50px;
      background: linear-gradient(to right, #4DA4FC, #CD65E3);

      #highlightDetail-vector-one-container {
        position: absolute;
        top: 0;

        #highlightDetail-vector-one-item {
          width: 100%;
        }
      }

      #highlightDetail-img-one {
        width: 100%;
        z-index: 999;
      }
    }

    #highlightDetail-more-info-container {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 100px;

      #highlightDetail-more-info-header {
        height: auto;
        width: 60%;
        padding: 50px;  
        text-align: center;
        margin-top: 150px;

        #highlightDetail-more-info-title {
          font-family: 'poppins-semibold', sans-serif;
          font-size: 2.5rem;
          color: #282828;
        }

        #highlightDetail-more-info-featMsg {
          font-family: 'poppins', sans-serif;
          font-size: 1.2rem;
          color: #282828;
        }
      }

      #highlightDetail-more-info-section-outer-container {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        color: #282828;

        .highlightDetail-more-info-section-inner-container-style-two {
          flex-direction: row-reverse !important;
        }

        .highlightDetail-more-info-section-inner-container {
          height: auto;
          width: 70%;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: flex-start;

          .highlightDetail-more-info-section-img-container-style-two {
            height: 450px !important;
            padding: 40px 20px 20px 20px !important;
          }

          .highlightDetail-more-info-section-img-container-style-three {
            height: auto !important;
            padding: 20px !important;
          }

          .highlightDetail-more-info-section-img-container {
            height: auto;
            width: 700px;
            padding: 100px 20px 20px 20px;
            backdrop-filter: blur(0px);
            box-shadow: 2px 2px 2px 2px rgba(75, 82, 247, 0.2);
            border-radius: 25px;
            position: relative;

            // highlight HR detail container and items
            #highlightHRDetailCtn {
              height: auto;
              width: 400px;
              position: absolute;
              left: -50px;
              top: 0px;
              display: flex;
              flex-direction: column;
              gap: 70px;
              
              .highlightHRDetailItem {
                height: auto;
                width: 100%;
                border-radius: 20px;
                background-color: #F2F4F7;
                box-shadow: 2px 2px 2px 2px rgba(black, 0.2);
                position: relative;

                .highlightHRDetailIcon {
                  position: absolute;
                  top: -20px;
                  left: 20px;
                }

                .highlightHRDetailHeader {
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  color: #282828;
                  padding: 15px;

                  .highlightHRDetailTitle {
                    font-family: 'poppins', sans-serif;
                    opacity: 60%;
                  }

                  .highlightHRDetailAbbre {
                    font-family: 'Poppins-semibold', sans-serif;
                    font-size: 1.2rem;
                  }
                }

                .highlightHRDetailFooter {
                  font-family: 'poppins', sans-serif;
                  font-size: 0.9rem;
                  padding: 15px;
                  opacity: 60%;
                  border-top: 1px solid rgba(gray, 0.5);
                }
              
              }
            }

            // add new images here
            .highlightDetail-more-info-section-img-one {
              position: absolute;
              right: 20px;
              top: 40px;
            }

            .highlightDetail-more-info-section-img-one-style-two {
              position: absolute;
              right: -70px;
              top: 10px;
            }

            .highlightDetail-more-info-section-img-one-style-three {
              height: 350px;
              position: absolute;
              right: -50px;
              top: -70px;
            }

            .highlightDetail-more-info-section-img-two {
              border: 1px solid blue;
            }
            
            .highlightDetail-more-info-section-img-three {
              position: absolute;
              right: 20px;
              bottom: 20px;
            }

            .highlightDetail-more-info-section-img-four {
              position: absolute;
              left: 30px;
              bottom: 100px;
            }

            .highlightDetail-more-info-section-img-five {
            
            }

            .highlightDetail-more-info-section-img-six {
              position: absolute;
              right: 10px;
              top: 0;
            }
          }

          .highlightDetail-more-info-section-header {
            height: auto;
            width: auto;
            margin: 0 100px;

            .highlightDetail-more-info-section-title {
              font-size: 3rem;
              font-family: 'Poppins-semibold', sans-serif;
              padding: 0 15px;
              line-height: 50px;
            }
  
            .highlightDetail-more-info-section-featMsg {
              height: auto;
              width: 100%;
              padding: 15px;
              font-family: 'Poppins', sans-serif;
            }
          }
        }

        
        .highlightDetail-more-info-divider-s-one {
          height: 300px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          
          .highlightDetail-more-info-divider-img {
            
          }
        }

        .highlightDetail-more-info-divider-s-two {
          height: 300px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .highlightDetail-more-info-divider-img {
            
          }
        }
        
      }
    }

  }
}

@media only screen and (min-width: 320px) and (max-width: 450px) {
  #highlightDetail-outer-container {
    padding-top: 80px;
    #highlightDetail-inner-container {
      #highlightDetail-hero-container {
        height: auto;
        padding: 50px 0 10px 0;
        width: 90%;
        #highlightDetail-title {
          width: 100%;
          line-height: normal;
          font-size: 1rem;
          margin-bottom: 2px;
        }

        #highlightDetail-footerText {
          width: 100%;
          font-size: 0.6rem;

        }
      }

      #highlightDetail-sample-img-container {
        padding: 10px;
        #highlightDetail-vector-one-container {

        }

        #highlightDetail-img-one {

        }
      }

      #highlightDetail-more-info-container {
        padding-bottom: 15px;
        
        #highlightDetail-more-info-header {
          width: 90%;
          margin-top: 20px;
          padding: 10px;
          #highlightDetail-more-info-title {
            font-size: 0.7rem;
            margin-bottom: 8px;
          }

          #highlightDetail-more-info-featMsg {
            font-size: 0.5rem;
          } 
        }

        #highlightDetail-more-info-section-outer-container {
          
          // if index !== 1 
          .highlightDetail-more-info-section-inner-container-style-two {

          }

          // if index === 1
          .highlightDetail-more-info-section-inner-container {
            width: 100%;
            padding: 5px;

            .highlightDetail-more-info-section-img-container-style-three {
              padding: 10px !important;
            }

            .highlightDetail-more-info-section-img-container-style-two {
              height: auto !important;
              width: 160px !important;
              padding: 10px 5px 5px 5px !important;
            }
            
            .highlightDetail-more-info-section-img-container {
              height: auto;
              width: 150px;
              padding: 10px 7px 7px 7px;
              border-radius: 10px;

              // id == more-info-one 
              .highlightDetail-more-info-section-img-six {
                height: 15px;
                right: 0px;
              }

              .highlight-one {
              }

              .highlightDetail-more-info-section-img-one-style-two {
                height: 55px;
                right: -22px;        

              }

              .highlightDetail-more-info-section-img-one {
                height: 75px;
                right: -20px;
                top: 20px;
              }

              // id == more-info-two
              // and if highlight_id === highlight-one
              #highlightHRDetailCtn {
                width: 200px;
                gap: 15px;
                left: -20px;
                .highlightHRDetailItem {
                  height: 40px;
                  width: 125px;
                  border-radius: 8px;
                  .highlightHRDetailIcon {
                    height: 20px;
                    top: -10px;
                    left: 10px;
                  }

                  .highlightHRDetailHeader {
                    padding: 2px 2px 0 0;
                    .highlightHRDetailTitle {
                      font-size: 0.4rem;
                    }

                    .highlightHRDetailAbbre {
                      font-size: 0.5rem;
                    }
                  }

                  .highlightHRDetailFooter {
                    font-size: 0.5rem;
                    padding: 2px 2px 2px 8px;
                  }

                }
              }

              // id == more-info-two
              // and if highlight_id === highlight-one
              .highlightDetail-more-info-section-img-five {
                // height: 120px;
                width: 100%;
              }

              // if false
              .highlightDetail-more-info-section-img-three {
                height: 90px;
                bottom: -30px;
                left: 10px;
              }

              .highlightDetail-more-info-section-img-four {
                height: 70px;
                bottom: 10px;
                left: 60px;
              }

              .highlightDetail-more-info-section-img-five {
                
              }

              // id == more-info-three
              // if true and if highlight-id === highlight-one
              // if true
              .highlightDetail-more-info-section-img-one-style-three {
                height: 70px;
                top: -10px;
                right: -10px;
              }

              // if false
              .highlightDetail-more-info-section-img-one {

              }

            }

            .highlightDetail-more-info-section-header {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              .highlightDetail-more-info-section-title {
                font-size: 1rem;
                padding: 0 !important;
              }

              .highlightDetail-more-info-section-featMsg {
                width: 100px;
                font-size: 0.5rem;
                padding: 0;
              }
            }

          }

          // id === more-info-one
          // if true
          .highlightDetail-more-info-divider-s-one {
            height: 50px;
            width: 100%;

            .highlightDetail-more-info-divider-img {
              height: 50px;
            }
          }
          // if false
          .highlightDetail-more-info-divider-s-two {
            height: 50px;
            width: 100%;
            justify-content: flex-start !important;
            .highlightDetail-more-info-divider-img {
              height: 45px;
            }
          }

        }
      }
    }
  }
}

@media only screen and (min-width: 450px) and (max-width: 650px) {
  #highlightDetail-outer-container {
    padding-top: 50px;
    #highlightDetail-inner-container {

      #highlightDetail-hero-container {
        height: auto;
        width: 70%;
        padding: 50px 0 10px 0;
        #highlightDetail-title {
          width: 100%;
          line-height: normal;
          font-size: 1.2rem;
          margin-bottom: 2px;
        }

        #highlightDetail-footerText {
          width: 80%;
          font-size: 0.6rem;

        }
      }

      #highlightDetail-sample-img-container {
        padding: 12px;
        #highlightDetail-vector-one-container {

        }

        #highlightDetail-img-one {

        }
      }

      #highlightDetail-more-info-container {
        padding-bottom: 15px;
        
        #highlightDetail-more-info-header {
          width: 70%;
          margin-top: 20px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          #highlightDetail-more-info-title {
            font-size: 0.9rem;
            margin-bottom: 10px;
          }

          #highlightDetail-more-info-featMsg {
            width: 70%;
            font-size: 0.6rem;
          } 
        }

        #highlightDetail-more-info-section-outer-container {
          
          // if index !== 1 
          .highlightDetail-more-info-section-inner-container-style-two {
          }

          // if index === 1
          .highlightDetail-more-info-section-inner-container {
            width: 100%;
            padding: 0px 25px;

            .highlightDetail-more-info-section-img-container-style-three {
              padding: 10px !important;
            }

            .highlightDetail-more-info-section-img-container-style-two {
              height: 120px !important;
              width: 160px !important;
              padding: 10px 5px 5px 5px !important;
            }
            
            .highlightDetail-more-info-section-img-container {
              height: auto;
              width: 150px;
              padding: 10px 7px 7px 7px;
              border-radius: 10px;

              // id == more-info-one 
              .highlightDetail-more-info-section-img-six {
                height: 15px;
                right: 0px;
              }

              .highlight-one {
              }

              .highlightDetail-more-info-section-img-one-style-two {
                height: 55px;
                right: -22px;        

              }

              .highlightDetail-more-info-section-img-one {
                height: 75px;
                right: -20px;
                top: 20px;
              }

              // id == more-info-two
              // and if highlight_id === highlight-one
              #highlightHRDetailCtn {
                width: 200px;
                gap: 15px;
                left: -20px;
                .highlightHRDetailItem {
                  height: 40px;
                  width: 125px;
                  border-radius: 8px;
                  .highlightHRDetailIcon {
                    height: 20px;
                    top: -10px;
                    left: 10px;
                  }

                  .highlightHRDetailHeader {
                    padding: 2px 2px 0 0;
                    .highlightHRDetailTitle {
                      font-size: 0.4rem;
                    }

                    .highlightHRDetailAbbre {
                      font-size: 0.5rem;
                    }
                  }

                  .highlightHRDetailFooter {
                    font-size: 0.5rem;
                    padding: 2px 2px 2px 8px;
                  }

                }
              }

              // id == more-info-two
              // and if highlight_id === highlight-one
              .highlightDetail-more-info-section-img-five {
                // height: 120px;
                width: 100%;
              }

              // if false
              .highlightDetail-more-info-section-img-three {
                height: 90px;
                bottom: -30px;
                left: 10px;
              }

              .highlightDetail-more-info-section-img-four {
                height: 70px;
                bottom: 10px;
                left: 60px;
              }

              .highlightDetail-more-info-section-img-five {
                
              }

              // id == more-info-three
              // if true and if highlight-id === highlight-one
              // if true
              .highlightDetail-more-info-section-img-one-style-three {
                height: 70px;
                top: -10px;
                right: -10px;
              }

              // if false
              .highlightDetail-more-info-section-img-one {

              }

            }

            .highlightDetail-more-info-section-header {
              width: 150px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .highlightDetail-more-info-section-title {
                width: 100%;
                font-size: 1.2rem;
                padding: 0 !important;
              }

              .highlightDetail-more-info-section-featMsg {
                width: 100%;
                font-size: 0.6rem;
                padding: 0;
              }
            }

          }

          // id === more-info-one
          // if true
          .highlightDetail-more-info-divider-s-one {
            height: 50px;
            width: 100%;

            .highlightDetail-more-info-divider-img {
              height: 50px;
            }
          }
          // if false
          .highlightDetail-more-info-divider-s-two {
            height: 50px;
            width: 100%;
            justify-content: flex-start !important;
            .highlightDetail-more-info-divider-img {
              height: 45px;
            }
          }

        }
      }
    }
  }
}


@media only screen and (min-width: 650px) and (max-width: 900px) {
  #highlightDetail-outer-container {
    padding-top: 80px;
    #highlightDetail-inner-container {

      #highlightDetail-hero-container {
        height: auto;
        width: 80%;
        padding: 60px 0 15px 0;
        #highlightDetail-title {
          width: 100%;
          line-height: 32px;
          font-size: 1.8rem;
          margin-bottom: 10px;
        }

        #highlightDetail-footerText {
          width: 90%;
          font-size: 0.9rem;
        }
      }

      #highlightDetail-sample-img-container {
        padding: 15px;
        #highlightDetail-vector-one-container {

        }

        #highlightDetail-img-one {

        }
      }

      #highlightDetail-more-info-container {
        padding-bottom: 15px;
        
        #highlightDetail-more-info-header {
          width: 80%;
          margin-top: 25px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          #highlightDetail-more-info-title {
            font-size: 1.3rem;
          }

          #highlightDetail-more-info-featMsg {
            width: 80%;
            font-size: 0.9rem;
          } 
        }

        #highlightDetail-more-info-section-outer-container {
          
          // if index !== 1 
          .highlightDetail-more-info-section-inner-container-style-two {
          }

          // if index === 1
          .highlightDetail-more-info-section-inner-container {
            width: 100%;
            padding: 15px;

            .highlightDetail-more-info-section-img-container-style-three {
              padding: 12px !important;
            }

            .highlightDetail-more-info-section-img-container-style-two {
              height: auto !important;
              padding-top: 15px !important;
            }

            .highlightDetail-more-info-section-img-container {
              width: 320px;
              padding: 25px 10px 10px 10px;
              border-radius: 20px;

              // id == more-info-one 
              .highlightDetail-more-info-section-img-six {
              }

              .highlight-one {

              }

              .highlightDetail-more-info-section-img-one-style-two {
                height: 120px;
                right: -40px;        
              }

              .highlightDetail-more-info-section-img-one {

              }

              // id == more-info-two
              // and if highlight_id === highlight-one
              #highlightHRDetailCtn {
                width: 200px;
                gap: 20px;
                left: -30px;
                .highlightHRDetailItem {
                  height: 70px;
                  width: 170px;
                  border-radius: 10px;
                  .highlightHRDetailIcon {
                    height: 25px;
                    top: -10px;
                    left: 10px;
                  }

                  .highlightHRDetailHeader {
                    padding: 5px;
                    .highlightHRDetailTitle {
                      font-size: 0.55rem;
                    }

                    .highlightHRDetailAbbre {
                      font-size: 0.7rem;
                    }
                  }

                  .highlightHRDetailFooter {
                    font-size: 0.6rem;
                    padding: 5px 5px 5px 5px;
                  }

                }
              }

              // id == more-info-two
              // and if highlight_id === highlight-one
              .highlightDetail-more-info-section-img-five {
                width: 100%;
                padding-top: 0;
              }

              // if false
              .highlightDetail-more-info-section-img-three {
                height: 200px;
                bottom: 20px;
                right: -20px;
              }

              .highlightDetail-more-info-section-img-four {
                height: 120px;
                bottom: 0px;
                left: 30px;
              }

              .highlightDetail-more-info-section-img-five {
                
              }

              // id == more-info-three
              // if true and if highlight-id === highlight-one
              // if true
              .highlightDetail-more-info-section-img-one-style-three {
                height: 100px;
                top: -10px;
                right: -10px;
              }

              // if false
              .highlightDetail-more-info-section-img-one {

              }

            }

            .highlightDetail-more-info-section-header {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              .highlightDetail-more-info-section-title {
                font-size: 1.5rem;
                padding: 0;
              }

              .highlightDetail-more-info-section-featMsg {
                width: 190px;
                font-size: 0.8rem;
                padding: 0;
              }
            }

          }

          // id === more-info-one
          // if true
          .highlightDetail-more-info-divider-s-one {
            height: 70px;
            width: 100%;

            .highlightDetail-more-info-divider-img {
              height: 70px;
            }
          }
          // if false
          .highlightDetail-more-info-divider-s-two {
            height: 70px;
            width: 100%;
            justify-content: flex-start !important;
            .highlightDetail-more-info-divider-img {
              height: 70px;
            }
          }

        }
      }
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  #highlightDetail-outer-container {
    padding-top: 100px;
    #highlightDetail-inner-container {

      #highlightDetail-hero-container {
        height: auto;
        width: 60%;
        padding: 70px 0 20px 0;
        #highlightDetail-title {
          width: 100%;
          line-height: 45px;
          font-size: 2.2rem;
          margin-bottom: 15px;
        }

        #highlightDetail-footerText {
          width: 70%;
          font-size: 1rem;
        }
      }

      #highlightDetail-sample-img-container {
        padding: 20px;
        #highlightDetail-vector-one-container {

        }

        #highlightDetail-img-one {

        }
      }

      #highlightDetail-more-info-container {
        padding-bottom: 15px;

        #highlightDetail-more-info-header {
          width: 60%;
          margin-top: 25px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          #highlightDetail-more-info-title {
            font-size: 1.5rem;
          }

          #highlightDetail-more-info-featMsg {
            width: 80%;
            font-size: 1.1rem;
          } 
        }

        #highlightDetail-more-info-section-outer-container {
          
          // if index !== 1 
          .highlightDetail-more-info-section-inner-container-style-two {

          }

          // if index === 1
          .highlightDetail-more-info-section-inner-container {
            width: 100%;
            padding: 15px;

            .highlightDetail-more-info-section-img-container-style-three {
              padding: 12px !important;
            }

            .highlightDetail-more-info-section-img-container-style-two {

            }

            .highlightDetail-more-info-section-img-container {
              width: 450px;
              padding: 30px 20px 20px 20px;
              border-radius: 20px;

              // id == more-info-one 
              .highlightDetail-more-info-section-img-six {
              }

              .highlight-one {

              }

              .highlightDetail-more-info-section-img-one-style-two {
                height: 200px;
                right: -70px;        
              }

              .highlightDetail-more-info-section-img-one {

              }

              // id == more-info-two
              // and if highlight_id === highlight-one
              #highlightHRDetailCtn {
                width: 300px;
                gap: 20px;
                left: -50px;
                .highlightHRDetailItem {
                  height: 100px;
                  width: 300px;
                  border-radius: 10px;
                  .highlightHRDetailIcon {
                    height: 35px;
                    top: -10px;
                    left: 10px;
                  }

                  .highlightHRDetailHeader {
                    padding: 5px;
                    .highlightHRDetailTitle {
                      font-size: 0.9rem;
                    }

                    .highlightHRDetailAbbre {
                      font-size: 1.1rem;
                    }
                  }

                  .highlightHRDetailFooter {
                    font-size: 0.9rem;
                    padding: 10px 5px 5px 5px;
                  }

                }
              }

              // id == more-info-two
              // and if highlight_id === highlight-one
              .highlightDetail-more-info-section-img-five {

              }

              // if false
              .highlightDetail-more-info-section-img-three {

              }

              .highlightDetail-more-info-section-img-four {
                
              }

              .highlightDetail-more-info-section-img-five {
                
              }

              // id == more-info-three
              // if true and if highlight-id === highlight-one
              // if true
              .highlightDetail-more-info-section-img-one-style-three {
                height: 220px;
                top: -30px;
                right: -40px;
              }

              // if false
              .highlightDetail-more-info-section-img-one {

              }

            }

            .highlightDetail-more-info-section-header {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              .highlightDetail-more-info-section-title {
                font-size: 2rem;
                padding: 0;
              }

              .highlightDetail-more-info-section-featMsg {
                width: 300px;
                font-size: 1.1rem;
                padding: 0;
              }
            }

          }

          // id === more-info-one
          // if true
          .highlightDetail-more-info-divider-s-one {
            height: 120px;
            width: 100%;

            .highlightDetail-more-info-divider-img {
              height: 120px;
            }
          }
          // if false
          .highlightDetail-more-info-divider-s-two {
            height: 120px;
            width: 100%;
            justify-content: flex-start !important;
            .highlightDetail-more-info-divider-img {
              height: 115px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  #highlightDetail-outer-container {
    padding-top: 120px;
    #highlightDetail-inner-container {

      #highlightDetail-hero-container {
        height: auto;
        width: 60%;
        padding: 70px 0 20px 0;
        #highlightDetail-title {
          width: 100%;
          line-height: 55px;
          font-size: 2.9rem;
          margin-bottom: 15px;
        }

        #highlightDetail-footerText {
          width: 80%;
          font-size: 1.2rem;
        }
      }

      #highlightDetail-sample-img-container {
        padding: 25px;
        #highlightDetail-vector-one-container {

        }

        #highlightDetail-img-one {

        }
      }

      #highlightDetail-more-info-container {
        padding-bottom: 15px;

        #highlightDetail-more-info-header {
          width: 60%;
          margin-top: 40px;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          #highlightDetail-more-info-title {
            font-size: 1.8rem;
          }

          #highlightDetail-more-info-featMsg {
            width: 80%;
            font-size: 1.2rem;
          } 
        }

        #highlightDetail-more-info-section-outer-container {
          
          // if index !== 1 
          .highlightDetail-more-info-section-inner-container-style-two {

          }

          // if index === 1
          .highlightDetail-more-info-section-inner-container {
            width: 80%;
            padding: 20px;
            .highlightDetail-more-info-section-img-container-style-three {
              padding: 15px !important;
            }

            .highlightDetail-more-info-section-img-container-style-two {

            }

            .highlightDetail-more-info-section-img-container {
              width: 550px;
              padding: 30px 20px 20px 20px;
              border-radius: 20px;

              // id == more-info-one 
              .highlightDetail-more-info-section-img-six {
              }

              .highlight-one {

              }

              .highlightDetail-more-info-section-img-one-style-two {
                height: 200px;
                right: -70px;        
              }

              .highlightDetail-more-info-section-img-one {

              }

              // id == more-info-two
              // and if highlight_id === highlight-one
              #highlightHRDetailCtn {
                width: 300px;
                gap: 20px;
                left: -50px;
                .highlightHRDetailItem {
                  height: 100px;
                  width: 300px;
                  border-radius: 10px;
                  .highlightHRDetailIcon {
                    height: 35px;
                    top: -10px;
                    left: 10px;
                  }

                  .highlightHRDetailHeader {
                    padding: 5px;
                    .highlightHRDetailTitle {
                      font-size: 0.9rem;
                    }

                    .highlightHRDetailAbbre {
                      font-size: 1.1rem;
                    }
                  }

                  .highlightHRDetailFooter {
                    font-size: 0.9rem;
                    padding: 10px 5px 5px 5px;
                  }

                }
              }

              // id == more-info-two
              // and if highlight_id === highlight-one
              .highlightDetail-more-info-section-img-five {

              }

              // if false
              .highlightDetail-more-info-section-img-three {

              }

              .highlightDetail-more-info-section-img-four {
                
              }

              .highlightDetail-more-info-section-img-five {
                
              }

              // id == more-info-three
              // if true and if highlight-id === highlight-one
              // if true
              .highlightDetail-more-info-section-img-one-style-three {
                height: 220px;
                top: -30px;
                right: -40px;
              }

              // if false
              .highlightDetail-more-info-section-img-one {

              }

            }

            .highlightDetail-more-info-section-header {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              .highlightDetail-more-info-section-title {
                font-size: 2rem;
                padding: 0;
              }

              .highlightDetail-more-info-section-featMsg {
                width: 300px;
                font-size: 1.1rem;
                padding: 0;
              }
            }

          }

          // id === more-info-one
          // if true
          .highlightDetail-more-info-divider-s-one {
            height: 120px;
            width: 100%;

            .highlightDetail-more-info-divider-img {
              height: 120px;
            }
          }
          // if false
          .highlightDetail-more-info-divider-s-two {
            height: 120px;
            width: 100%;
            justify-content: flex-start !important;
            .highlightDetail-more-info-divider-img {
              height: 115px;
            }
          }
        }
      }
    }
  }
}