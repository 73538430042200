#featuredspecsV2-outer-container {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 150px;
  margin-bottom: 50px;

  #featuredspecsV2-inner-container {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 350px;

    #featuredspecsV2-container-one {
      height: auto;
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      gap: 50px;
      
      // bubble reasons why :)
      #featuredspecsV2-left-sectionn {
        height: auto;
        width: auto;
        position: relative;

        // yep bubbles
        #featuredspecsV2-bubble-one {
          height: 100px;
          width: 100px;
          position: absolute;
          border-radius: 100%;
          background-color: rgba(74, 82, 247, 0.7);
          animation: bubbles 5s linear 0s infinite;
        }

        #featuredspecsV2-left-section {
          height: auto;
          width: 450px;
          font-family: 'Poppins', sans-serif;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
  
          #featuredspecs-featmsg {
            font-size: 1.5rem;
            color: #4750ff;
          }
  
          #featuredspecs-title {
            color: #282828;
            font-size: 3rem;
            font-family: 'Poppins-SemiBold', sans-serif;
            line-height: 60px;
          }
        }
      }

      #featuredspecsV2-right-section {
        height: auto;
        width: 750px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;

        .featuredspecsV2-item {
            height: auto;
            width: auto;
            color: #282828;
            font-size: 1.2rem;
            list-style-type: none;
            padding: 20px;
            background: rgba(255, 255, 255, 0.4);
            backdrop-filter: blur(0px);
            box-shadow: 2px 2px 2px 2px rgba(75, 82, 247, 0.2);
            border-radius: 25px;
            transition: all 0.9s;
            font-family: 'Poppins', sans-serif;
            &:hover {
                background-color: #4A52F7;
                color: white;
                cursor: pointer;
            }
        }
        // E585D3 #BB2DA1
      }
    }

    .featuredspecsV2-container-two-outer {
      height: auto;
      width: auto;
      position: relative;

      #featuredspecsV2-bubble-two {
        height: 75px;
        width: 75px;
        border-radius: 100%;
        background-color: black;
        position: absolute;
        top: 0;
        left: 0;
        animation: bubblesAgain 3s linear 0s infinite;
      }
      
      #featuredspecsV2-container-two {
        height: auto;
        width: 100%;
        padding: 50px;
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(0px);
        box-shadow: 2px 2px 10px 2px rgba(75, 82, 247, 0.2);
        border-radius: 25px;
  
        #featuredspecsV2-left-sectionn {
  
          #featuredspecsV2-titlee {
            color: #282828;
            font-size: 3rem;
            font-family: 'Poppins-semibold', sans-serif;
            margin-bottom: 15px;
            padding-left: 50px;
          }
        }
  
        #featuredspecsV2-right-sectionn {
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 50px;
          
          .featuredspecsV2-itemm-container {
            height: 340px;
            width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 40px 20px 20px 20px;
            backdrop-filter: blur(0px);
            box-shadow: 2px 2px 2px 2px rgba(75, 82, 247, 0.2);
            border-radius: 25px;
  
            .featuredspecsV2-itemm-icon-container {
              .featuredspecsV2-itemm-icon {
                height: 40px;
              }
            }
  
            .featuredspecsV2-itemm-title {
              color: #282828;
              font-family: 'poppins-semibold', sans-serif;
              font-size: 1.5rem;
              text-align: center;
              margin-bottom: 15px;
            }
  
            .featuredspecsV2-itemm-desc {
              color: gray;
              font-family: 'poppins', sans-serif;
              font-size: 0.9rem;
              text-align: center;
            }
          } 
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 450px) {
  #featuredspecsV2-outer-container {
    padding: 0;
    margin-top: 20px;
    #featuredspecsV2-inner-container {
      #featuredspecsV2-container-one {
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        
        #featuredspecsV2-left-sectionn {
          #featuredspecsV2-bubble-one {
            height: 25px;
            width: 25px;
          }

          #featuredspecsV2-left-section {
            justify-content: center;
            width: 100%;
            #featuredspecs-featmsg {
              font-size: 0.5rem;
            }

            #featuredspecs-title {
              font-size: 0.7rem;
              line-height: normal;
            }
          }
        }

        #featuredspecsV2-right-section {
          width: 100%;
          gap: 5px;
          .featuredspecsV2-item {
            box-shadow: 2px 2px 2px 1px rgba(75, 82, 247, 0.2);
            font-size: 0.5rem;
            padding: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 450px) and (max-width: 650px) {
  #featuredspecsV2-outer-container {
    padding: 0;
    margin-top: 50px;
    #featuredspecsV2-inner-container {
      #featuredspecsV2-container-one {
        flex-direction: row;
        gap: 10px;
        width: 90%;
        padding: 0 0 0 5px;
        justify-content: center;
        margin-top: 20px;

        #featuredspecsV2-left-sectionn {
          #featuredspecsV2-bubble-one {
            height: 25px;
            width: 25px;
          }

          #featuredspecsV2-left-section {
            justify-content: center;
            width: 100%;
            #featuredspecs-featmsg {
              font-size: 0.7rem;
            }

            #featuredspecs-title {
              font-size: 0.9rem;
              line-height: normal;
            }
          }
        }

        #featuredspecsV2-right-section {
          width: 100%;
          gap: 5px;
          padding: 0 15px;
          .featuredspecsV2-item {
            box-shadow: 2px 2px 2px 1px rgba(75, 82, 247, 0.2);
            font-size: 0.5rem;
            padding: 5px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 650px) and (max-width: 750px) {
  #featuredspecsV2-outer-container {
    padding: 0;
    margin-top: 50px;
    #featuredspecsV2-inner-container {
      #featuredspecsV2-container-one {
        flex-direction: row;
        gap: 10px;
        width: 85%;
        padding: 0 0 0 20px;
        justify-content: center;

        #featuredspecsV2-left-sectionn {
          #featuredspecsV2-bubble-one {
            height: 30px;
            width: 30px;
          }

          #featuredspecsV2-left-section {
            justify-content: center;
            width: 100%;
            #featuredspecs-featmsg {
              font-size: 0.8rem;
            }

            #featuredspecs-title {
              font-size: 1.1rem;
              line-height: normal;
            }
          }
        }

        #featuredspecsV2-right-section {
          width: 100%;
          gap: 9px;
          padding: 0 15px;
          .featuredspecsV2-item {
            box-shadow: 2px 2px 2px 1px rgba(75, 82, 247, 0.2);
            font-size: 0.7rem;
            padding: 8px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 750px) and (max-width: 999px) {
  #featuredspecsV2-outer-container {
    padding: 0;
    margin-top: 100px;
    #featuredspecsV2-inner-container {
      #featuredspecsV2-container-one {
        flex-direction: row;
        gap: 10px;
        width: 85%;
        padding: 0 0 0 20px;
        justify-content: center;

        #featuredspecsV2-left-sectionn {
          #featuredspecsV2-bubble-one {
            height: 30px;
            width: 30px;
          }

          #featuredspecsV2-left-section {
            justify-content: center;
            width: 100%;
            #featuredspecs-featmsg {
              font-size: 0.9rem;
            }

            #featuredspecs-title {
              font-size: 1.3rem;
              line-height: normal;
            }
          }
        }

        #featuredspecsV2-right-section {
          width: 100%;
          gap: 9px;
          padding: 0 15px;
          .featuredspecsV2-item {
            box-shadow: 2px 2px 2px 1px rgba(75, 82, 247, 0.2);
            font-size: 0.8rem;
            padding: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 999px) and (max-width: 1200px) {
  #featuredspecsV2-outer-container {
    padding: 0;
    margin-top: 120px;
    #featuredspecsV2-inner-container {
      #featuredspecsV2-container-one {
        flex-direction: row;
        gap: 10px;
        width: 85%;
        padding: 0 0 0 20px;
        justify-content: center;

        #featuredspecsV2-left-sectionn {
          #featuredspecsV2-bubble-one {
            height: 30px;
            width: 30px;
          }

          #featuredspecsV2-left-section {
            justify-content: center;
            width: 100%;
            #featuredspecs-featmsg {
              font-size: 1rem;
            }

            #featuredspecs-title {
              font-size: 1.4rem;
              line-height: normal;
            }
          }
        }

        #featuredspecsV2-right-section {
          width: 100%;
          gap: 12px;
          padding: 0 15px;
          .featuredspecsV2-item {
            box-shadow: 2px 2px 2px 1px rgba(75, 82, 247, 0.2);
            font-size: 0.9rem;
            padding: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  #featuredspecsV2-outer-container {
    padding: 0;
    margin-top: 120px;
    #featuredspecsV2-inner-container {
      #featuredspecsV2-container-one {
        flex-direction: row;
        gap: 10px;
        width: 85%;
        padding: 0 0 0 20px;
        justify-content: center;

        #featuredspecsV2-left-sectionn {
          #featuredspecsV2-bubble-one {
            height: 30px;
            width: 30px;
          }

          #featuredspecsV2-left-section {
          justify-content: center;
            width: 100%;
            #featuredspecs-featmsg {
              font-size: 1.4rem;
            }

            #featuredspecs-title {
              font-size: 2.2rem;
              line-height: normal;
            }
          }
        }

        #featuredspecsV2-right-section {
          width: 100%;
          gap: 15px;
          padding: 0 15px;
          .featuredspecsV2-item {
            box-shadow: 2px 2px 2px 2px rgba(75, 82, 247, 0.2);
            font-size: 1.2rem;
            padding: 20px;
          }
        }
      }
    }
  }
}

@keyframes bubbles {
  0% {
    transform: translate(0px, 170px);
  }

  50% {
    transform: translate(20px, 170px);
  }

  100% {
    transform: translate(0px, 170px);
  }
}

@keyframes bubblesAgain {
  0% {
    top: 0px;
    left: -40px;
  }

  25% {
    top: 120px;
    left: 25px;
  }

  50% {
    top: 150px;
    left: 50px;
  }

  75% {
    top: 100px;
    left: 25px;
  }

  100% {
    top: 0px;
    left: -40px;
  }
}