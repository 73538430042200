#contact-us-outer-container {
  height: auto;
  width: 100%;
  padding: 100px 0 0 0;
  animation: faded 1s ease-in-out forwards;

  #contact-us-inner-container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    #contact-us-header-section {
      height: 120px;
      width: 99%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(to right, #83BEFC, #8086f9);
      border-radius: 25px;

      h1 {
        font-family: 'poppins-semibold', sans-serif;
        font-size: 2rem;
        color: #FFF5EA;
      }
    }

    #contact-us-body-section {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      #contact-us-body-section-one {
        height: auto;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          font-family: 'poppins', sans-serif;
          font-size: 1.8rem;
          margin-bottom: 20px;
        }

        h3 {
          width: 60%;
          font-size: 1rem;
          text-align: center;  
        }
      }

      #contact-us-body-section-two {
        height: auto;
        width: 70%;        
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 100px;
        padding: 50px;

        #contact-us-form {
          // height: 500px;
          // width: 500px;
          padding: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;
          background: rgba(255, 255, 255, 0.2);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
          backdrop-filter: blur(0px);
          border-radius: 25px;

          #contact-us-title {
            width: 100%;
            font-family: 'poppins', sans-serif;
            
            h1 {
              font-size: 1.1rem;
            }

            h2 { 
              font-size: 0.9rem;
              opacity: 60%; 
            }

          }

          .contact-us-form-item {
            width: 400px;
          }

          #contact-us-form-button-outer-container {
            height: auto;
            width: 400px;
            #contact-us-form-button {
              height: auto;
              width: auto;
              padding: 10px 40px;
              font-size: 1.1rem;
              font-family: 'poppins', sans-serif;
              border-radius: 12px;
              background-color: #4750FF;
              color: #FFF5EA;
              cursor: pointer;
            }
          }
        }
    
        #real-contact-us-info-outer-container {
          height: auto;
          width: auto;
          display: flex;
          flex-direction: column;
          gap: 40px;

          .real-contact-us-info-container {
            height: auto;
            width: auto;
            display: flex;
            flex-direction: row;
  
            .real-contact-us-info-icon-container {
            
              .real-contact-us-info-icon {
                height: 40px;              
              }
            }
  
            .real-contact-us-info-texts {
              margin-left: 15px;
              width: 250px;
              font-family: 'poppins', sans-serif;
  
              .real-contact-us-info-title {
                font-size: 1.1rem;
  
              }
              .real-contact-us-info-featMsgOne, .real-contact-us-info-featMsgTwo {
                font-size: 1rem;
                width: auto;
                opacity: 80%;
              }
  
            }
          }
        }
      }

      #contact-us-body-section-three {
        height: 600px;
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;

        #real-contact-us-GIS {
          height: 100%;
        }
      }
    }

  
  }
}

@keyframes faded {
  from{
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

// LFG
@media only screen and (min-width: 320px) and (max-width: 650px) {
  #contact-us-outer-container {
    #contact-us-inner-container {
      #contact-us-body-section {
        #contact-us-body-section-two {
          width: 100%;
          gap: 50px;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          #contact-us-form {
            width: 100%;
            padding: 40px;
            gap: 15px;

            .contact-us-form-item {
              width: 100%;
            }

            #contact-us-form-button-outer-container {
              width: 100%;
              #contact-us-form-button {
                font-size: 1rem;
              }
            }
          }

          #real-contact-us-info-outer-container {
            gap: 10px;

            .real-contact-us-info-container {
              padding: 15px;
              .real-contact-us-info-icon-container {
                .real-contact-us-info-icon {
                  height: 30px;
                  margin-bottom: 10px;
                }
              }

              .real-contact-us-info-texts {
                width: 270px;
                .real-contact-us-info-title {
                  font-size: 0.9rem;
                }

                .real-contact-us-info-featMsgOne, .real-contact-us-info-featMsgTwo {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }

        #contact-us-body-section-three {
          height: auto;
          width: 100%;
          padding: 15px;
          #real-contact-us-GIS {
          }
        }
      }
    }
  }
} 

@media only screen and (min-width: 650px) and (max-width: 800px) {
  #contact-us-outer-container {
    #contact-us-inner-container {
      #contact-us-body-section {
        #contact-us-body-section-two {
          width: 100%;
          gap: 50px;
          flex-direction: column;
          align-items: center;
          #real-contact-us-info-outer-container {
            gap: 10px;

            .real-contact-us-info-container {
              padding: 15px;
              .real-contact-us-info-icon-container {
                .real-contact-us-info-icon {
                  height: 35px;
                  margin-bottom: 10px;
                }
              }

              .real-contact-us-info-texts {
                width: 300px;
              }
            }
          }
        }

        #contact-us-body-section-three {
          height: 450px;
          width: 100%;
          padding: 15px;
        }
      }
    }
  }
} 

@media only screen and (min-width: 800px) and (max-width: 1000px) {
  #contact-us-outer-container {
    #contact-us-inner-container {
      #contact-us-body-section {
        #contact-us-body-section-two {
          width: 100%;
          gap: 50px;
        }
      }
    }
  }
} 