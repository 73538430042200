.section-title-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    font-family: 'Poppins-SemiBold', sans-serif;
    .section-title-feat{
        text-transform: uppercase;
        color: #777777;
        font-size: 12px;
        letter-spacing: 2px;
        margin: 0 0 20px;
    }
    .section-title-title{
        width: 60%;
        color: #282828;
        font-size: 3rem;
        margin: 0 0 15px;
        text-align: center;
    }

    #sectionTitleContainer {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        .section-title-desc {
            color: #777777;
            font-size: 0.9rem;
            text-align: center;
        }

        .section-title-button {
            height: 70px;
            width: 350px;
            font-size: 1.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-family: 'Poppins', sans-serif;
            color: #fff ;
            border-radius: 12px;
            background: linear-gradient(90deg, #4A52F7 0%, #4a75f7 100%);
            &:hover {
                cursor: pointer;
            }
        }
    }
}


//Responsive Traits Screen
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .section-title-container{
        width: 78%;
        margin: 50px 0 40px 0 !important;
        .section-title-feat {

        }

        .section-title-title {
            width: 80%;
            font-size: 2.2rem;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .section-title-container{
        width: 78%;
        margin: 20px 0 20px 0 !important;
        .section-title-feat {

        }

        .section-title-title {
            width: 80%;
            font-size: 2rem;
        }
    }
}

@media only screen and (max-width: 991px) {
    .section-title-container{
        width: 78%;
        margin-bottom: 40px !important;
        .section-title-feat {

        }

        .section-title-title {
            width: 80%;
            font-size: 1.7rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .section-title-container{
        width: 80%;
        margin: 0 !important;
        .section-title-title{
            width: 80%;
            font-size: 1.6rem;
            color: #282828;
        }
        #sectionTitleContainer {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 575px) {
    .section-title-container{
        width: 90%;
        margin: 0 !important;
        .section-title-title{
            width: 80%;
            font-size: 1.5rem;
        }

        .section-title-feat {
            
        }

        #sectionTitleContainer {
            .section-title-desc {
                font-size: 0.7rem;
            }
        }
    }

    #sectionTitleContainer {
        width: 100%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 420px) {
    .section-title-container {
        text-align: center;
        width: 100%;

        .section-title-feat {

        }

        .section-title-title {
            width: 80%;
        }
    }
}