.call-to-action--section-wrap{
    background-image: url('../../../../public/assets/bv-footer-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 820px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // z-index: 200;
    color: #fff;
    .call-to-action--section-bg{
        position: absolute;
        display: none;
        // z-index: -1;
    }
    .call-to-action--msg-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .call-to-action--msg-pre{
            font-family: 'Poppins-Bold', sans-serif;
            font-size: 36px;
            text-align: center;
            padding: 10px;
        }
        .call-to-action--msg-pro{
            font-family: sans-serif;
            font-size: 20px;
            text-align: center;
        }
        .call-to-action--msg-action{
            font-family: 'Poppins-Medium';
            color: #000;
            margin-top: 40px;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 10px;
            border: 2px solid #0076ff;
            padding: 18px 30px;
            transition: all 0.5s ease;
            letter-spacing: 2px;
            &:hover{
                background: transparent;
                color: #fff;
                border: 2px solid #fff;
            }
        }
    }
}
