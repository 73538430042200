* {
    margin: 0;
    box-sizing: border-box;
}

.org--wrap{
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 80px;
}

@keyframes fadeIn {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    
}

.org-header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.org-header h1{
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 2rem;
    background: rgb(77,28,145);
    // background: linear-gradient(90deg, rgba(77,28,145,1) 0%, rgba(77,28,145,1) 35%, rgba(85,102,116,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.org-header h1 p{
    font-family: 'Poppins-Bold', sans-serif;
    display: inline;

}

.org-content{
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.org-card{
}

// SECTIONS
#sectionOne {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sectionTwo {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row-reverse;
}

#innerOneSectionTwo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
}

#innerTwoSectionTwo {  
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sectionThree, #sectionFour {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin-top: 40px;
}

#sectionTwoLeftSectionSecondInner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#sectionTwoRightSecondInner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}

#innerOneSectionFour, .sectionTwoRightFourthInner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    gap: 40px;
}

.sectionTwoRightThirdInner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 20px 0 20px;
    gap: 20px;
}

.orgCard {
    height: auto;
    // width: 120px;
    border-radius: 5%;
    position: relative;
}

.bookMarkIconOne {
    height: 10px;
    width: 15px;
    position: absolute;
    top: 0;
    left: 5px;
}

.bookMarkIconTwo {
    height: 10px;
    width: 15px;
    position: absolute;
    top: 0;
    left: 15px;
}

.org-card, .org-card p, .org-card h2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.org-card-img{
    width: 170px;
    height: 200px;
    img{

        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 2%;
        border-top-right-radius: 2%;
        background-position: top;
    }
}

#sectionTwoRightFourthInner {
    margin-top: 20px;
}

.org-card-content{
    width: 170px;
    flex: 1;
    // background-color: black;
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(0px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    border-bottom-left-radius: 2%;
    border-bottom-right-radius: 2%;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 5px;
}

.adjustedCardContent .team-position {
    font-size: 10px !important;
}

.org-card-content .team-fullName{
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 15px;
    color: #222;
}

.org-card-content .team-content{
    font-family: 'Poppins-Medium', sans-serif;
    font-size: 14px;
    color: #777;
}

.org-card-content .team-position{
    font-family: 'Poppins-Medium', sans-serif;
    font-size: 12px;
    color: #222;
    opacity: 0.5;
    // color: white;
}

.org-card-social{
    display: flex;
    justify-content: center;
    align-items: center;       
    margin: 10px 0;      

}

.org-card-social a:hover{
    transform: scale(1.2);
    transition: all 0.3s ease-in;
}

.org-card-social a{
    margin: 0 5px;
    transition: all 0.3s ease-in-out;   
}

// new css team profile :)

// remove after fixing layout
#sectionTwoLeftSection, #sectionTwoRightSection {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

#sectionThreeInnerCtnTwo {
    display: flex;
    flex-direction: row;
    gap: 50px;
}

#sectionTwoLeftSectionFirstInner, #sectionTwoRightSectionOuterContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.devTeamHeaderCtn, .businessDevTeamHeaderCtn {
    height: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader { 
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 1.1rem;
    background: rgb(77,28,145);
    // background: linear-gradient(90deg, rgba(77,28,145,1) 0%, rgba(77,28,145,1) 35%, rgba(85,102,116,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 15px;
}

#sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo {
    display: flex;
    flex-direction: row;
    gap: 20px;  
}

@media only screen and (min-width: 320px) and (max-width: 370px) {
    #sectionTwoLeftSection {
        width: 30%;
    }

    #sectionTwoRightSection {
        width: 70%;
    }

    .org-content {
        width: 100%;
    }

    .org-header h1 {
        font-size: 1.1rem;
    }

    .businessDevTeamHeaderCtn, .devTeamHeaderCtn {
        height: 110px;
        margin-top: 10px;
    }

    .orgCard, .org-card-content {
        width: 45px;
    }

    .org-card-content {
        padding: 3px !important;
    }

    .org-card-img {
        width: 45px;
        height: 40px;
    }

    #sectionTwoRightFirstInner, .sectionTwoRightFourthInner, #innerOneSectionFour {
        gap: 10px !important;
        flex-direction: column;
    }

    .sectionTwoRightFourthInner {
        flex-direction: row;
        height: 80px;
    }

    #sectionTwoRightFifthInner {
        margin-top: 5px;
    }

    .team-fullName {
        font-size: 0.3rem !important;
    }

    .team-position {
        font-size: 0.25rem !important;
    }  

    .adjustedCardContent .team-position {
        font-size: 0.25rem !important;
    }

    .bookMarkIconOne {
        height: 5px;
        width: 7px;
        left: 2px;
    }

    .bookMarkIconTwo {
        height: 5px; 
        width: 7px;
        left: 8px;
    }

    .sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader {
        font-size: 0.4em;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        column-gap: 10px;
        margin-top: 2px;
        height: 80px;
        width: 100%;
        justify-content: center;
    }

    .org-card-img img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
}

@media only screen and (min-width: 370px) and (max-width: 450px) {
    #sectionTwoLeftSection {
        width: 35% !important;
    }

    #sectionTwoRightSection {
        width: 65% !important;
    }

    .org-content {
        width: 100%;
    }

    .org-header h1 {
        font-size: 1.1rem;
    }

    .businessDevTeamHeaderCtn, .devTeamHeaderCtn {
        height: 110px;
        margin-top: 10px;
    }

    .orgCard, .org-card-content {
        width: 45px;
    }

    .org-card-content {
        padding: 3px !important;
    }

    .org-card-img {
        width: 45px;
        height: 45px;
    }

    #sectionTwoRightFirstInner, #sectionTwoLeftSectionSecondInner, .sectionTwoRightFourthInner, #innerOneSectionFour {
        gap: 15px !important;
        flex-direction: column;
    }

    #sectionTwoRightFirstInner {
        flex-direction: column-reverse !important;
    }

    .sectionTwoRightFourthInner {
        flex-direction: row;
    }

    .team-fullName {
        font-size: 0.3rem !important;
    }

    .team-position {
        font-size: 0.25rem !important;
    }  

    .adjustedCardContent .team-position {
        font-size: 0.25rem !important;
    }

    .bookMarkIconOne {
        height: 7px;
        width: 10px;
        left: 0px;
    }

    .bookMarkIconTwo {
        height: 7px; 
        width: 10px;
        left: 12px;
    }

    .sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader {
        font-size: 0.4em;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        gap: 15px;
    }

    .org-card-img img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        width: 100%;
        justify-content: center;
    }
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
    #sectionTwoLeftSection {
        width: 35% !important;
    }

    #sectionTwoRightSection {
        width: 65% !important;
    }

    .org-content {
        width: 100%;
    }

    .org-header h1 {
        font-size: 1.1rem;
    }

    .businessDevTeamHeaderCtn, .devTeamHeaderCtn {
        height: 120px;
        margin-top: 10px;
    }

    .orgCard, .org-card-content {
        width: 55px;
    }

    .org-card-content {
        padding: 3px !important;
    }

    .org-card-img {
        width: 55px;
        height: 55px;
    }

    #sectionTwoRightFirstInner, #sectionTwoLeftSectionSecondInner, .sectionTwoRightFourthInner, #innerOneSectionFour {
        gap: 15px !important;
        flex-direction: column;
    }

    #sectionTwoRightFirstInner {
        flex-direction: column-reverse !important;
    }

    .sectionTwoRightFourthInner {
        flex-direction: row;
    }

    .team-fullName {
        font-size: 0.3rem !important;
    }

    .team-position {
        font-size: 0.25rem !important;
    }  

    .adjustedCardContent .team-position {
        font-size: 0.25rem !important;
    }

    .bookMarkIconOne {
        height: 7px;
        left: 0px;
    }

    .bookMarkIconTwo {
        height: 7px; 
        left: 12px;
    }

    .sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader {
        font-size: 0.5em;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        gap: 15px;
    }

    .org-card-img img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        width: 100%;
        justify-content: center;
    }
}

@media only screen and (min-width: 520px) and (max-width: 550px) {
    .org-header h1 {
        font-size: 1.1rem;
    }

    .businessDevTeamHeaderCtn, .devTeamHeaderCtn {
        height: 120px;
    }

    .orgCard, .org-card-content {
        width: 55px;
    }

    .org-card-content {
        padding: 3px !important;
    }

    .org-card-img {
        width: 55px;
        height: 55px;
    }

    #sectionTwoRightFirstInner, #sectionTwoLeftSectionSecondInner, .sectionTwoRightFourthInner, #innerOneSectionFour {
        gap: 15px !important;
        flex-direction: column;
    }

    #sectionTwoRightFirstInner {
        flex-direction: column-reverse !important;
    }

    .sectionTwoRightFourthInner {
        flex-direction: row;
    }

    .team-fullName {
        font-size: 0.3rem !important;
    }

    .team-position {
        font-size: 0.25rem !important;
    }  

    .adjustedCardContent .team-position {
        font-size: 0.25rem !important;
    }

    .bookMarkIconOne {
        height: 7px;
        left: 0px;
    }

    .bookMarkIconTwo {
        height: 7px; 
        left: 12px;
    }

    .sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader {
        font-size: 0.55rem;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        gap: 15px;
    }

    .org-card-img img {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        width: 100%;
        justify-content: flex-end;
    }
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
    .org-content {
        width: 80%;
    }

    .org-header h1 {
        font-size: 1.2rem;
    }

    .businessDevTeamHeaderCtn, .devTeamHeaderCtn {
        height: 140px;
    }

    .orgCard, .org-card-content {
        width: 60px;
    }

    .org-card-content {
        padding: 3.5px !important;
    }

    .org-card-img {
        width: 60px;
        height: 60px;
    }

    #sectionTwoRightSecondInner {
        gap: 20px;
    }

    #sectionTwoRightFirstInner, #sectionTwoLeftSectionSecondInner, .sectionTwoRightFourthInner, #innerOneSectionFour {
        gap: 15px !important;
        flex-direction: column;
    }

    #sectionTwoRightFirstInner {
        flex-direction: column-reverse !important;
    }

    .sectionTwoRightFourthInner {
        flex-direction: row;
    }

    .team-fullName {
        font-size: 0.35rem !important;
    }

    .team-position {
        font-size: 0.3rem !important;
    }  

    .adjustedCardContent .team-position {
        font-size: 0.3rem !important;
    }

    .bookMarkIconOne {
        height: 7px;
        left: 0px;
    }

    .bookMarkIconTwo {
        height: 7px; 
        left: 12px;
    }

    .sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader {
        font-size: 0.55rem;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        gap: 15px;
    }

    .org-card-img img {
        border-top-left-radius: 8%;
        border-top-right-radius: 8%;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        width: 85%;
        justify-content: flex-end;
    }
}

@media only screen and (min-width: 790px) and (max-width: 900px) {
    .org-header h1 {
        font-size: 1.4rem;
    }

    .businessDevTeamHeaderCtn, .devTeamHeaderCtn {
        height: 170px;
    }

    .org-content {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    #sectionTwo {
        flex-direction: row-reverse;
    }

    .orgCard, .org-card-content {
        width: 75px;
    }

    .org-card-img {
        width: 75px;
        height: 70px;
    }

    #sectionTwoRightFirstInner, #sectionTwoLeftSectionSecondInner, .sectionTwoRightFourthInner, #innerOneSectionFour, #sectionThreeInnerCtnTwo {
        gap: 15px !important;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        gap: 15px;
        width: 80%;
        justify-content: flex-end;
    }

    .team-fullName {
        font-size: 0.4rem !important;
    }

    .team-position {
        font-size: 0.38rem !important;
    }

    .adjustedCardContent .team-position {
        font-size: 0.33rem !important;
    }

    .org-card-img img {
        border-top-left-radius: 8%;
        border-top-right-radius: 8%;
    }

    .sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader {
        font-size: 0.7rem;
    }
}


@media only screen and (min-width: 650px) and (max-width: 790px) {
    .org-header h1 {
        font-size: 1.2rem;
    }

    .businessDevTeamHeaderCtn, .devTeamHeaderCtn {
        height: 150px;
    }

    .orgCard, .org-card-content {
        width: 70px;
    }

    .org-card-content {
        padding: 5px !important;
    }

    .org-card-img {
        width: 70px;
        height: 60px;
    }

    #sectionTwoRightSecondInner {
        gap: 20px;
    }

    #sectionTwoRightFirstInner, #sectionTwoLeftSectionSecondInner, .sectionTwoRightFourthInner, #innerOneSectionFour {
        gap: 15px !important;
        flex-direction: column;
    }

    #sectionTwoRightFirstInner {
        flex-direction: column-reverse !important;
    }

    .sectionTwoRightFourthInner {
        flex-direction: row;
    }

    .team-fullName {
        font-size: 0.4rem !important;
    }

    .team-position {
        font-size: 0.3rem !important;
    }  

    .adjustedCardContent .team-position {
        font-size: 0.33rem !important;
    }

    .bookMarkIconOne {
        height: 7px;
        left: 0px;
    }

    .bookMarkIconTwo {
        height: 7px; 
        left: 12px;
    }

    .sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader {
        font-size: 0.7rem;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        gap: 15px;
    }

    .org-card-img img {
        border-top-left-radius: 8%;
        border-top-right-radius: 8%;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        width: 90%;
        justify-content: flex-end;
    }
}

@media only screen and (min-width: 790px) and (max-width: 900px) {
    .org-header h1 {
        font-size: 1.4rem;
    }

    .businessDevTeamHeaderCtn, .devTeamHeaderCtn {
        height: 170px;
    }

    .org-content {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    #sectionTwo {
        flex-direction: row-reverse;
    }

    .orgCard, .org-card-content {
        width: 75px;
    }

    .org-card-img {
        width: 75px;
        height: 70px;
    }

    #sectionTwoRightFirstInner, #sectionTwoLeftSectionSecondInner, .sectionTwoRightFourthInner, #innerOneSectionFour, #sectionThreeInnerCtnTwo {
        gap: 15px !important;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionThreeInnerCtnTwo {
        gap: 15px;
        width: 80%;
        justify-content: flex-end;
    }

    .team-fullName {
        font-size: 0.4rem !important;
    }

    .team-position {
        font-size: 0.38rem !important;
    }

    .adjustedCardContent .team-position {
        font-size: 0.33rem !important;
    }

    .org-card-img img {
        border-top-left-radius: 8%;
        border-top-right-radius: 8%;
    }

    .sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader {
        font-size: 0.7rem;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
    #sectionTwo {
        flex-direction: row-reverse;
    }

    .orgCard, .org-card-content {
        width: 100px;
    }

    .team-fullName {
        font-size: 0.5rem !important;
    }

    .team-position {
        font-size: 0.45rem !important;
    }

    .adjustedCardContent .team-position {
        font-size: 0.45rem !important;
    }

    .org-card-img{
        width: 100px;
        height: 100px;
    }

    #sectionTwoLeftSectionCtnOne, #sectionTwoLeftSectionCtnTwo, #sectionTwoRightFirstInner, #sectionTwoLeftSectionSecondInner, .sectionTwoRightFourthInner, #innerOneSectionFour, #sectionThreeInnerCtnTwo {
        gap: 20px !important;
    }

    .sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader {
        font-size: 0.8rem;
    }

    .org-card-img img {
        border-top-left-radius: 8%;
        border-top-right-radius: 8%;
    }
}

@media only screen and (min-width:1200px) and (max-width: 2000px) {
    .org-content{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .orgCard, .org-card-content {
        width: 100px;
        border-bottom-left-radius: 8%;
        border-bottom-right-radius: 8%;
    }
    
    .team-fullName {
        font-size: 0.5rem !important;
    }

    .team-position {
        font-size: 0.45rem !important;
    }

    .adjustedCardContent .team-position {
        font-size: 0.45rem !important;
    }
    
    .org-card-img{
        width: 100px;
        height: 100px;
    }

    .org-card-img img {
        border-top-left-radius: 8%;
        border-top-right-radius: 8%;
    }

    #sectionTwoRightFirstInner, #sectionTwoLeftSectionSecondInner, .sectionTwoRightFourthInner, #innerOneSectionFour, #sectionThreeInnerCtnTwo {
        gap: 20px !important;
    }

    .sectionTwoLeftSectionHeader , .sectionTwoRightSectionHeader {
        font-size: 0.9rem;
    }
}