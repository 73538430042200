.featured-specs-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2f4f7;
    padding: 130px 0;
    .featured-specs-cont{
        display: flex;
        justify-content: center;
        width: auto;
        height: 464px;
        &.featured-specs-cont2{
            margin-top: 120px;
            height: 423px;
            width: 100%;
            .featured-specs-cont-title-wrap{
                width: 40%;
                display: flex;
                justify-content: flex-end;
                .section-title-v2-container{
                    width: auto;
                }
            }
        }
    }
    .featured-specs-cont[border-render='false']{
        flex-direction: column;
    }
    .featured-specs-img-wrap{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .featured-specs-img2{
            height: 300px;
            width: 500px;
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
        }
        .featured-specs-img1{
            height: 300px;
            width: 500px;
            border-bottom-left-radius: 50px;
            border-top-left-radius: 50px;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }
}

//Responsive Traits Screen
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .featured-specs-wrap{
        .featured-specs-cont{
            height: 512px;
            &.featured-specs-cont2{
                height: 423px;
            }
            .featured-specs-img1{
                height: 250px;
                width: 290px;
            }
            .featured-specs-img2{
                height: 300px;
                width: 360px;
            }
        }
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
    .featured-specs-wrap {
        .featured-specs-cont {
            .featured-specs-img-wrap {
                .featured-specs-img1 {
                    height: 500px;
                }

                .featured-specs-img2 {
                    height: 400px;
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .featured-specs-wrap {
        margin-left: auto;
        margin-right: auto;
        .featured-specs-cont{
            height: auto;
            width: 70%;
            justify-content: center;
            align-items: center;
            &.featured-specs-cont2{
                height: auto;
            }
            .featured-specs-img-wrap{
                .featured-specs-img1{
                    height: 273px;
                    width: 350px;
                    margin: 50px 0;
                }
                .featured-specs-img2{
                    height: 411px;
                    width: 350px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .featured-specs-wrap{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        .featured-specs-cont{
            height: auto;
            justify-content: center;
            align-items: center;
            &.featured-specs-cont2{
                height: auto;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .featured-specs-wrap{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        .featured-specs-cont{
            height: auto;
            &.featured-specs-cont2{
                height: auto;
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 574px) {
    .featured-specs-wrap{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        .featured-specs-cont{
            width: 100%;
            height: auto;
            &.featured-specs-cont2{
                height: auto;
            }
        }
    }
}