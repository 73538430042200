.about-page--about-logo{
    max-width: 350px;
}


.about-page--wrap{
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    
}

.about-page--section-about {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 120px;
    // flex-flow: wrap;
    .about-page--left-section{
        // padding: 0 50px 0 0;
        width: 100%;
        .about-page--about-logo{
            font-size: 80px;
            display: flex;
            font-family: 'Poppins', sans-serif;
            p{
                color: rgb(0, 152, 255);
            }
            .about-page-logo--symbol{
                font-size: 95px;
                letter-spacing: -20px;
            }
        }
        .about-page--main-header{
            .about-page--main-header-title{
                display: flex;
                flex-direction: column;
                h1{
                    font-family: 'Poppins-SemiBold', sans-serif;
                    font-size: 36px;
                    color: #222;
                    // outline: 1px solid red;
                }
                p{
                    font-family: 'Poppins-Medium', sans-serif;
                    font-size: 14px;
                    color: #777;
                    text-align: start;
                    // padding: 0 16px 0 0;
                    // outline: 1px solid red;
                }
            }
            .about-picture{

            }
        }
    }
    .about-page--right-section{
        width: 100%;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .about-page--right-content{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-start;
            flex-wrap: wrap;
            height: 100%;
            gap: 25px;
            .about-page--right-header-title{
                width: 400px;
                padding: 20px;
                background: rgba(255, 255, 255, 0.2);
                box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
                backdrop-filter: blur(0px);
                border-radius: 10px;
                h1{
                    font-family: 'Poppins-SemiBold', sans-serif;
                    font-size: 36px;
                    color: #222;
                    text-align: center;
                }
                p{
                    font-family: 'Poppins-Medium', sans-serif;
                    font-size: 14px;
                    color: #777;
                    text-align: center;
                }
            }
        }
        
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .about-page--section-about {
        width: 85%;
        .about-page--left-section {
            .about-page--main-header {
                .about-page--main-header-title {
                    h1 {
                        font-size: 1.4rem;
                        text-align: center;
                    }
                    p {
                        font-size: 0.7rem;
                        text-align: center;
                    }
                }
            }
        }

        .about-page--right-section {
            .about-page--right-content {
                .about-page--right-header-title {
                    background: rgba(255, 255, 255, 0.4);
                    width: 100%;
                    h1 {
                        font-size: 1.45rem;
                    }
                    p {
                        font-size: 0.65rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 480px) and (max-width: 599px) {
    .about-page--section-about {
        width: 80%;
        .about-page--right-section {
            .about-page--right-content {
                .about-page--right-header-title {
                    width: 100%;
                    padding: 20px;
                    h1 {
                        font-size: 1.4rem;
                    }
                    p {
                        font-size: 0.65rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 799px) {
    .about-page--section-about {
        width: 75%;
        .about-page--right-section {
            .about-page--right-content {
                .about-page--right-header-title {
                    width: 350px;
                    padding: 15px;
                    h1 {
                        font-size: 1.5rem;
                    }
                    p {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 800px) and (max-width: 1023px) {
    .about-page--section-about{
        width: 68%;
        .about-page--right-section{
            .about-page--right-content{
                .about-page--right-header-title{
                    width: 250px;
                    padding: 20px;
                    h1 {
                        font-size: 1.8rem;
                    }
                    p {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .about-page--section-about{
        .about-page--right-section{
            .about-page--right-content{
                .about-page--right-header-title{
                    width: 300px;
                    padding: 20px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1600px) {
    .about-page--section-about {
        .about-page--right-section{
            .about-page--right-content{
                .about-page--right-header-title{
                    width: 350px;
                    padding: 20px;
                }
            }
        }
    }
}
