.product-specs--section {
    width: 100%;
    margin-bottom: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .product-specs--h2 {
        width: 80%;
    }

    .product-specs--summary {
        width: 80%;
    }
}

@media only screen and (max-width: 575px) {
    .product-specs--section {
        .product-specs--h2 {
            width: 90%;
        }
        .product-specs--summary {
            width: 90%;
        }
    }
}