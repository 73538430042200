#faq-outer-container {
  height: auto;
  width: 100%;
  background-color: #f2f4f7;
  padding: 100px 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  #faq-inner-container {
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(8px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    border-radius: 25px;
    padding: 50px;

    #accordion-section {
      height: auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      flex-direction: row;

      .accordion-inner-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .accordion-container {
          width: 500px;
          padding: 10px;
          border-radius: 20px;
          margin: 10px 5px;
  
          .accordion-summary {
            .accordion-question {
              font-size: 1.2rem;
              font-family: 'Poppins', sans-serif;
            }
          }
  
          .expandIcon {
            height: 15px !important;
            width: 50px;
            max-width: 50px;
          }
  
          .accordion-details {
            .accordion-answer {
              font-size: 1.1rem;
              font-family: 'Poppins', sans-serif;
            }
          }
        }
      }
    }
  }
}

// media lets go
@media only screen and (min-width: 320px) and (max-width: 450px) {
  #faq-outer-container {
    padding: 30px 0px;

    #faq-inner-container {
      border-radius: 12px;
      padding: 30px 0;
      width: 90%;

      #accordion-section {
        align-items: center;
        .accordion-inner-section {
          .accordion-container {
            width: 90%;
            padding: 5px;
            .accordion-summary {
              .accordion-question {
                font-size: 0.9rem;
              }
            }
  
            .accordion-details {
              .accordion-answer {
                font-size: 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 450px) and (max-width: 750px) {
  #faq-outer-container {
    padding: 30px 0px;

    #faq-inner-container {
      border-radius: 12px;
      padding: 30px 0;
      width: 90%;

      #accordion-section {
        width: 100%;
        align-items: center;

        .accordion-inner-section {
          width: 100%;
          .accordion-container {
            width: 90%;
            .accordion-summary {
              .accordion-question {
                font-size: 1.1rem;
              }
            }
  
            .accordion-details {
              .accordion-answer {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 750px) and (max-width: 950px) {
  #faq-outer-container {
    padding: 30px 0px;

    #faq-inner-container {
      border-radius: 12px;
      padding: 50px 0;
      width: 95%;

      #accordion-section {
        width: 100%;
        align-items: center;

        .accordion-inner-section {
          width: 100%;
          .accordion-container {
            width: 85%;
            .accordion-summary {
              .accordion-question {
                font-size: 1.2rem;
              }
            }
  
            .accordion-details {
              .accordion-answer {
                font-size: 1.1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 950px) and (max-width: 1600px) {
  #faq-outer-container {
    padding: 30px 0px;

    #faq-inner-container {
      border-radius: 12px;
      padding: 50px 0;
      width: 80%;

      #accordion-section {
        width: 100%;

        .accordion-inner-section {
          .accordion-container {
            width: 500px;
            .accordion-summary {
              .accordion-question {
                font-size: 1.1rem;
              }
            }
  
            .accordion-details {
              .accordion-answer {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}