// LFG
#teamV3-outer-container {
  width: 100%;
  padding: 150px;

  #teamV3-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    #header-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      #title {
        font-family: 'poppins-semibold', sans-serif;
        font-size: 2rem;
      }

      #desc {
        font-family: 'poppins', sans-serif;
        opacity: 0.8;
        width: 400px;
      }
    }

    #body-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      #seeAllTeam {
        font-family: 'poppins', sans-serif;
        font-size: 1.2rem;
        opacity: 0.8;
        background: linear-gradient(to right, #4A52F7, #4DA4FC);
        padding: 12px 25px;
        border-radius: 15px;
        color: #F7F7F7;
        margin-top: 40px;
      }

      #section-one {
        display: flex;
        gap: 20px;
        
        .item-container {
          height: 300px;
          padding: 25px;

          .item-img {
            height: 240px;
            width: 220px;
            border-radius: 15px;
          }

          .item-details {
            width: 200px;
            position: relative;
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(8px);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
            border-radius: 10px;
            bottom: 40px;
            left: 50%;
            transform: translate(-50%);
            padding: 10px 15px;
            position: relative;

            .name {
              font-family: 'poppins-semibold', sans-serif;
              font-size: 0.9rem;
            }

            .position {
              font-family: 'poppins', sans-serif;
              font-size: 0.7rem;
              opacity: 0.8;
            }

            img {
              height: 1rem;
              width: 1rem;
              position: absolute;
              bottom: 5px;
              right: 5px;
            }
          }
        }
      }

      #section-two {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
        
        .item-container {
          height: 300px;
          padding: 25px;

          .item-img {
            height: 240px;
            width: 220px;
            border-radius: 15px;
          }

          .item-details {
            height: 70px;
            width: 200px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(8px);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
            border-radius: 10px;
            bottom: 40px;
            left: 50%;
            transform: translate(-50%);
            padding: 10px 15px;

            .name {
              font-family: 'poppins-semibold', sans-serif;
              font-size: 0.9rem;
            }

            .position {
              font-family: 'poppins', sans-serif;
              font-size: 0.7rem;
            }

            img {
              height: 1rem;
              width: 1rem;
              position: absolute;
              bottom: 2px;
              right: 2px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  #teamV3-outer-container {
    padding: 130px 10px;
    #teamV3-inner-container {
      gap: 15px;
      #header-container {
        #title {
          font-size: 1.4rem;
        }

        #desc {
          width: 60%;
          font-size: 0.7rem;
        }
      }

      #body-container {
        gap: 30px;
        #section-one {
          gap: 30px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .item-container {
            padding: 5px;
            height: 225px;
            width: 245px;
            .item-img {
              height: 100%;
              width: 100%;
            }

            .item-details {
              width: 95%;
              padding: 5px 10px;
              bottom: 20px;
              .name {
                font-size: 0.8rem;                
              }

              .position {
                font-size: 0.6rem;                
              }

              img {

              }
            }
          }
        }

        #section-two {
          column-gap: 5px;
          row-gap: 40px;
          width: 100%;
          .item-container {
            padding: 5px;
            height: 225px;
            width: 245px;
            .item-img {
              height: 100%;
              width: 100%;
            }

            .item-details {
              height: 55px;
              width: 95%;
              padding: 5px 10px;
              bottom: 20px;
              .name {
                padding-right: 21px;
                font-size: 0.8rem;   
              }

              .position {
                font-size: 0.6rem;                
              }

              img {

              }
            }
          }
        }

      }
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
  #teamV3-outer-container {
    padding: 130px 20px;
    #teamV3-inner-container {
      gap: 15px;
      #header-container {
        #title {
          font-size: 1.4rem;
        }

        #desc {
          width: 60%;
          font-size: 0.7rem;
        }
      }

      #body-container {
        gap: 10px;
        #section-one {
          gap: 0px;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          .item-container {
            padding: 20px;
            height: 175px;
            width: 175px;
            .item-img {
              height: 100%;
              width: 100%;
            }

            .item-details {
              width: 95%;
              padding: 5px 10px;
              bottom: 20px;
              .name {
                font-size: 0.6rem;                
              }

              .position {
                font-size: 0.55rem;                
              }

              img {

              }
            }
          }
        }

        #section-two {
          column-gap: 5px;
          row-gap: 40px;
          width: 100%;
          .item-container {
            padding: 5px;
            height: 160px;
            width: 160px;
            .item-img {
              height: 100%;
              width: 100%;
            }

            .item-details {
              height: 55px;
              width: 95%;
              padding: 5px 10px;
              bottom: 20px;
              .name {
                padding-right: 21px;
                font-size: 0.6rem;   
              }

              .position {
                font-size: 0.55rem;                
              }

              img {

              }
            }
          }
        }

      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 950px) {
  #teamV3-outer-container {
    padding: 150px 20px;
    #teamV3-inner-container {
      gap: 25px;
      #header-container {
        #title {
          font-size: 1.8rem;
        }

        #desc {
          font-size: 0.9rem;
        }
      }

      #body-container {
        gap: 20px;
        #section-one {
          gap: 15px;
          .item-container {
            padding: 20px;
            height: 190px;
            width: 190px;
            .item-img {
              height: 100%;
              width: 100%;
            }

            .item-details {
              width: 95%;
              padding: 5px 10px;
              bottom: 20px;
              .name {
                font-size: 0.6rem;                
              }

              .position {
                font-size: 0.55rem;                
              }

              img {

              }
            }
          }
        }

        #section-two {
          column-gap: 5px;
          width: 100%;
          .item-container {
            padding: 20px;
            height: 180px;
            width: 180px;
            .item-img {
              height: 100%;
              width: 100%;
            }

            .item-details {
              height: 60px;
              width: 95%;
              padding: 5px 10px;
              bottom: 20px;
              .name {
                padding-right: 5px;
                font-size: 0.7rem;   
              }

              .position {
                font-size: 0.6rem;                
              }

              img {

              }
            }
          }
        }

      }
    }
  }
}

@media only screen and (min-width: 950px) and (max-width: 1250px) {
  #teamV3-outer-container {
    padding: 150px 20px;
    #teamV3-inner-container {
      gap: 25px;
      #header-container {
        #title {

        }

        #desc {

        }
      }

      #body-container {
        gap: 30px;
        #section-one {
          gap: 15px;
          .item-container {
            padding: 20px;
            height: 200px;
            width: 200px;
            .item-img {
              height: 100%;
              width: 100%;
            }

            .item-details {
              width: 95%;
              .name {
                font-size: 0.7rem;                
              }

              .position {
                font-size: 0.6rem;                
              }

              img {

              }
            }
          }
        }

        #section-two {
          gap: 5px;
          width: 80%;
          .item-container {
            padding: 20px;
            height: 190px;
            width: 190px;
            .item-img {
              height: 100%;
              width: 100%;
            }

            .item-details {
              height: 60px;
              width: 95%;
              padding: 5px 10px;
              .name {
                padding-right: 5px;
                font-size: 0.7rem;   
              }

              .position {
                font-size: 0.6rem;                
              }

              img {

              }
            }
          }
        }

      }
    }
  }
}

@media only screen and (min-width: 1250px) and (max-width: 1600px) {
  #teamV3-outer-container {
    #teamV3-inner-container {
      gap: 25px;
      #header-container {
        #title {

        }

        #desc {

        }
      }

      #body-container {
        #section-one {
          gap: 15px;
          .item-container {
            padding: 20px;
            height: 250px;
            .item-img {
              height: 100%;
              width: 100%;
            }

            .item-details {
              width: 90%;
              .name {
                font-size: 0.8rem;                
              }

              .position {
                font-size: 0.6rem;                
              }

              img {

              }
            }
          }
        }

        #section-two {
          gap: 10px;
          .item-container {
            padding: 20px;
            height: 210px;
            width: 210px;
            .item-img {
              height: 100%;
              width: 100%;
            }

            .item-details {
              height: 60px;
              width: 90%;
              .name {
                font-size: 0.7rem;                
              }

              .position {
                font-size: 0.55rem;                
              }

              img {

              }
            }
          }
        }
      }
    }
  }
}