.team-profile--wrap{
    color: #fff;
}

.team-profile--wrap{
    background-image: url('../../../../public/assets/purple-banner.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    margin: 0 0 80px 0;
}

@keyframes fadeIn {
from{
    opacity: 0;
}
to {
    opacity: 1;
}

}

.team-profile--content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 50px 0;
    padding: 30px;
    border-radius: 15px;
    min-height: 300px;
    max-height: 600px;
    width: 80%;
}

.team-profile--img{
    display: flex;
    justify-content: end;
    align-self: center;
    width: 33%;
    margin: 0 20px;
}

.team-profile--img img{
    border-radius: 50%;
    height: 300px;
    width: 300px;
}

.team-profile--aboutme{
width: 66%;
display: flex;
flex-direction: column;
justify-content: space-evenly;
margin: 0 40px;
height: 100%;
}

.team-profile--aboutme-header{
    display: flex;
    flex-direction: column;
}

.team-profile--aboutme-header h1{
    font-family: 'Poppins-SemiBold', sans-serif; 
    font-size: 2.5rem;
    display: inline;
}

.team-profile--aboutme-header p{
    font-size: 1rem;
    color: #fff;
    display: inline;
}

.team-profile--aboutme-header h1,
.team-profile--aboutme-header p {
    margin: 0;
    padding: 0; 
}

.team-profile--text{
    margin-top: 50px;
}

.team-profile--text p{
    color: #fff;
}

.team-profile--text h1{
    font-family: 'Poppins-SemiBold', sans-serif;
    font-size: 1.5rem;
}

// hello :))))
#teamProfileOuterContainer {
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 150px;
}

#teamProfileInnerOneContainer {
    height: auto;
    width: 45%;
}

#teamProfileInnerTwoContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#teamImgUrl {
    height: 450px;
    background-size: contain;
    border-radius: 5%;
}

#teamProfileAbout { 
    height: auto;
    width: 100%;
    font-family: 'Poppins-SemiBold', sans-serif;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

#teamProfileHeader h1 {
    font-size: 3.5rem;
    background: linear-gradient(to right, #4A52F7, #4DA4FC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#teamProfileHeader p {
    font-size: 2rem;
    margin-bottom: 40px;
}

#teamProfileInfo h1 {
    font-size: 1.2rem;
}

#teamProfileInfo p {
    text-align: justify;
    padding-right: 50px;
    margin-top: 10px;
}

#linkedInLogo {
    height: 30px;
    margin-left: 10px;
}

// icon container
#iconContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    padding-right: 50px;
}

.techStackContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

// icons baby 
.iconsbaby {
    height: 30px;
    margin: 0 5px 0 5px;
}

.techSection {
    color: grey;
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
}

.techSection span {
    margin-right: 15px;
}

#upperProfileInfo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


@media only screen and (max-width: 600px) {
    .team-profile--content{
        flex-direction: column;
        padding: 20px 0;    
    }

    .team-profile--img img{
        height: 150px;
        width: 150px;
        margin: 0;
        padding: 0;
    }

    .team-profile--img{
        width: 100%;
        justify-content: center;
    }

    .team-profile--aboutme{
        margin-top: 25px;
        padding: 0;
        width: 100%;
    }

    .team-profile--aboutme-header h1{
        font-size: 2rem;
    }

}

@media only screen and (min-width: 320px) and (max-width: 399px) {
    #iconContainer {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-right: 0;
    }

    #teamProfileOuterContainer {
        height: auto;
        flex-direction: column-reverse;
    }
    
    #teamProfileInnerOneContainer {
        width: 80%;
    }

    #teamProfileInnerTwoContainer {
        height: 250px;
    }

    #teamProfileHeader h1 {
        font-size: 1.9rem;
    }
    
    #teamProfileHeader p {
        font-size: 1.1rem;
    }
    
    #teamProfileInfo h1 {
        font-size: 1.2rem;
    }
    
    #teamProfileInfo p {
        font-size: 0.75rem;
        padding-right: 0;
    }

    #teamProfileInnerTwoContainer {
        justify-content: center;
        width: 60%;
        position: relative;
    }

    #teamImgUrl {
        height: 250px;
    }
}

@media only screen and (min-width: 400px) and (max-width: 789px) {
    #iconContainer {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-right: 0;
    }

    .techStackContainer {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }

    #teamProfileOuterContainer {
        height: auto;
        flex-direction: column-reverse;
    }
    
    #teamProfileInnerOneContainer {
        width: 80%;
    }

    #teamProfileHeader h1 {
        font-size: 1.9rem;
    }
    
    #teamProfileHeader p {
        font-size: 1.1rem;
    }
    
    #teamProfileInfo h1 {
        font-size: 1.2rem;
    }
    
    #teamProfileInfo p {
        font-size: 0.75rem;
        padding-right: 0;
    }

    #teamProfileInnerTwoContainer {
        height: auto;
        justify-content: center;
        width: auto;
        position: relative;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    #teamImgUrl {
        height: 250px;
    }
}

@media only screen and (min-width: 790px) and (max-width: 900px) {
    #iconContainer {
        width: 100%;
        flex-wrap: wrap;
    }

    #teamProfileInnerTwoContainer {
        height: 400px;
    }

    #teamProfileHeader h1 {
        font-size: 1.9rem;
    }
    
    #teamProfileHeader p {
        font-size: 1.1rem;
    }
    
    #teamProfileInfo h1 {
        font-size: 1rem;
    }
    
    #teamProfileInfo p {
        font-size: 0.65rem;
        padding-right: 30px;
    }

    #teamImgUrl {
        height: 250px;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1199px) {
    #iconContainer {
        width: 100%;
        flex-wrap: wrap;
    }

    #teamProfileInnerTwoContainer {
        height: 70%;
        border-radius: 3%;
    }

    #teamProfileHeader h1 {
        font-size: 2rem;
    }
    
    #teamProfileHeader p {
        font-size: 1.2rem;
        margin-bottom: 40px;
    }
    
    #teamProfileInfo h1 {
        font-size: 1rem;
    }
    
    #teamProfileInfo p {
        font-size: 0.7rem;
        padding-right: 40px;
    }

    #teamImgUrl {
        height: 260px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    #iconContainer {
        width: 100%;
        flex-wrap: wrap;
    }

    #teamProfileHeader h1 {
        font-size: 3.3rem;
    }

    #teamProfileHeader p {
        font-size: 1.5rem;
        margin-bottom: 40px;
    }
    
    #teamProfileInfo p {
        font-size: 0.9rem;
        padding-right: 40px;
    }
}