#uppernav-outer-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    z-index: 999;

    .uppernav-wrap{
        width: 90%;
        font-family: 'Poppins', sans-serif;
        color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        height: auto;
        font-weight: 400;
        padding: 10px 20px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(8px);
        // box-shadow: 2px 2px 2px 1px rgba(75, 82, 247, 0.2);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);

        .uppernav-logo{
            display: flex;
            font-family: 'Poppins-', sans-serif;
            font-size: 40px;
            color: #fff;
            p{
                color: #0098ff;
            }
            .uppernav-logo--symbol{
                font-size: 45px;
            }
    
            img{
                max-height: 45px;
                max-width: 200px;
            }

            #bitverseLogoHamburger {
                height: 45px;
                width: 45px;
                display: none;
            }
        }
        .uppernav-navItems{
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: auto;
            height: auto;
            cursor: pointer;
            li {
                padding: 0 15px 0 15px;
            }
        }
    
        .uppernav-burger--wrap{
            display: none;
        }
        .uppernav-navItems a:hover, .uppernav-button:hover{
            color: #4d1c91;
            // font-size: 20px;
            transition: all 0.2s ease-in;
        }
    
        .uppernav-navItems a, .uppernav-button{
            transition: font-size 0.3s ease-in-out;
        }
    }

    .bookDemoNavItem {
        background: linear-gradient(to right, #4A52F7, #4DA4FC);
        color: white !important;
        padding: 10px;
        border-radius: 5px;
    }
    
}

#uppernav-outer-wrap {
    .upperNav-wrap-style-two {
        background-color: rgba(white, 0.9);
        width: auto !important;
        .uppernav-logo {
            padding-left: 10px;
            margin-right: 10px;
        }
    }
}

// @media only screen and (min-width: 1200px) and (max-width: 1600px) {
//     #uppernav-outer-wrap {
//         .uppernav-wrap {
//             .uppernav-logo {

//             }
//         }
//     }
// }

// @media only screen and (min-width: 320px) and (max-width: 400px) {
//     #uppernav-outer-wrap {
        
//         border: 1px solid red;
//         .uppernav-wrap {
//             border: 1px solid blue;
//             width: 100%;
//             .uppernav-logo {
//                 font-size: 20px;
//                 border: 1px solid red;
//             }
//         }
//     }
// }

@media only screen and (max-width: 991px) {
    .uppernav-wrap{
        justify-content: space-around;

        .uppernav-navItems{
            display: none !important;
        }
        .uppernav-button{
            display: none !important;
        }
        .uppernav-burger--wrap{
            display: block !important;
        }
    }
}

@media only screen and (max-width: 400px) {
    .uppernav-wrap{
        justify-content: space-around;

        .uppernav-logo {
            img {
                display: none;
            }

            #bitverseLogoHamburger {
                display: block !important;
            }
        }
    }
}





