#brandAreaOuterContainer {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f2f4f7;
    padding: 100px;
    .brand-container {
        height: auto;
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;
    
        .brand-item {
        }
    
        img{
            height: 200px;
        }
    }
}

// lets gooo media queries
@media only screen and (min-width: 320px) and (max-width: 450px) {
    #brandAreaOuterContainer {
        padding: 20px;
        .brand-container {
            width: 100%;
            flex-direction: row;

            .brand-item {
                height: 70px;
            }
        }
    }
}

@media only screen and (min-width: 450px) and (max-width: 600px) {
    #brandAreaOuterContainer {
        padding: 20px;
        .brand-container {
            width: 100%;
            flex-direction: row;

            .brand-item {
                height: 90px;
            }
        }
    }
}

@media only screen and (min-width: 600px) and (max-width: 750px) {
    #brandAreaOuterContainer {
        padding: 30px;
        .brand-container {
            width: 100%;
            flex-direction: row;

            .brand-item {
                height: 110px;
            }
        }
    }
}

@media only screen and (min-width: 750px) and (max-width: 900px) {
    #brandAreaOuterContainer {
        padding: 20px;
        .brand-container {
            width: 100%;
            flex-direction: row;

            .brand-item {
                height: 100px;
            }
        }
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
    #brandAreaOuterContainer {
        padding: 20px;
        .brand-container {
            width: 100%;
            flex-direction: row;

            .brand-item {
                height: 100px;
            }
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {

    #brandAreaOuterContainer {
        padding: 20px;
        .brand-container {
            width: 70%;
            flex-direction: row;
            gap: 20px;

            .brand-item {
                height: 150px;
            }
        }
    }
}