.contact-input--label{
    width: 50%;
    display: flex;
    align-items: center;
}


.contact-us--section-aboutt {
    .contact-us--main-section {
        .contact-us--form {
            .contact-us--input-label-wrap {
                .MuiFormLabel-root {
                    font-family: 'Poppins', sans-serif;
                    width: 250px;
                }

                .MuiFormControl-root {
                    width: 300px;
                }

                .MuiOutlinedInput-input {
                    font-family: 'Poppins', sans-serif;
                    background: rgba(#f2f4f7, 0.3);
                    padding: 1rem 24px;
                }
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 450px) {
    .contact-us--section-about {
        .contact-us--main-section {
            .contact-us--form {
                .contact-us--input-label-wrap {
                    .MuiFormLabel-root {
                        font-family: 'Poppins', sans-serif;
                        width: 250px;
                    }

                    .MuiFormControl-root {
                        width: 200px;
                    }

                    .MuiOutlinedInput-input {
                        font-family: 'Poppins', sans-serif;
                        background: rgba(#f2f4f7, 0.3);
                        padding: 1rem 24px;
                    }
                }
            }
        }
    } 
}

@media only screen and (min-width: 450px) and (max-width: 750px) {
    .contact-us--section-about {
        .contact-us--main-section {
            .contact-us--form {
                .contact-us--input-label-wrap {
                    .MuiFormLabel-root {
                        font-family: 'Poppins', sans-serif;
                        width: 250px;
                    }

                    .MuiFormControl-root {
                        width: 240px;
                    }

                    .MuiOutlinedInput-input {
                        font-family: 'Poppins', sans-serif;
                        background: rgba(#f2f4f7, 0.3);
                        padding: 1rem 24px;
                    }
                }
            }
        }
    } 
}

@media only screen and (min-width: 750px) and (max-width: 1099px) {
    .contact-us--section-about {
        .contact-us--main-section {
            .contact-us--form {
                .contact-us--input-label-wrap {
                    .MuiFormLabel-root {
                        display: flex;
                        width: auto;
                        font-size: 0.85rem;
                    }
    
                    .MuiFormControl-root {
                        width: 200px;
                    }
    
                    .MuiOutlinedInput-input {
                     
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1299px) {
    .contact-us--section-about {
        .contact-us--main-section {
            .contact-us--form {
                .contact-us--input-label-wrap {
                    .MuiFormLabel-root {
                    }
    
                    .MuiFormControl-root {
                        width: 250px;
                    }
    
                    .MuiOutlinedInput-input {
                     
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1474px) {
    .contact-us--section-about {
        .contact-us--main-section {
            .contact-us--form {
                .contact-us--input-label-wrap {
                    .MuiFormLabel-root {
                    }
    
                    .MuiFormControl-root {
                        width: 220px;
                    }
    
                    .MuiOutlinedInput-input {
                     
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1475px) and (max-width: 1700px) {
    .contact-us--section-about {
        .contact-us--main-section {
            .contact-us--form {
                .contact-us--input-label-wrap {
                    .MuiFormLabel-root {
                    }
    
                    .MuiFormControl-root {
                        width: 250px;
                    }
    
                    .MuiOutlinedInput-input {
                     
                    }
                }
            }
        }
    }
}