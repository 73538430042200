$width  : 150px;
$height : 70px;
$primary_color : #3084AC;
$border_color--hover : lighten($primary_color, 80);
$stroke : 10px;
$border: $stroke solid darken($primary_color,10);
$transition_duration:100ms;
$pipeline_width: $width+$stroke;
$pipeline_height: $height+$stroke;
$pipes : 4;

.contactSubmitBtn {
    font-size: 1.1rem !important;
    background-color: #4d1c91 !important;
    color: white !important;
}

@function border-pipeline ($transition_duration, $pipe) {
    @return $transition_duration*$pipe;
}

@mixin border-animation($dimension, $easing ,$pipe ) 
{
    transition: $transition_duration  $dimension $easing border-pipeline($transition_duration,$pipe);
    -web-kit-transition: $transition_duration  $dimension $easing border-pipeline($transition_duration,$pipe);
}
// body {margin:0;padding:0; background:black;}

.button-style--v1-wrap {
    width: $width ;
    height: $height ;
    background: $primary_color;
    margin: auto;
    border:$border;
    text-align: center;
    color: #fff; 
    position:relative;
    top: 50px;
    cursor: pointer;
    transition: ($transition_duration*$pipes)+$transition_duration ease;
    &:before {
        position: absolute; 
        width: 0; 
        height:$stroke; 
        content: ''; 
        background: rgb(46, 127, 165);
        top:-$stroke; 
        left:-$stroke; 
        @include border-animation( width, ease, 3);
    }
    &:after {
        position: absolute; 
        width:$stroke; 
        height:0;
        content: ''; 
        background: rgb(46, 127, 165); 
        top:-$stroke; 
        right: -$stroke; 
        @include border-animation( height, ease, 2);
    }
    .button-style--v1-wrap-inner:after{
        position: absolute; 
        width: 0; 
        height:$stroke; 
        content: ''; 
        background: rgb(46, 127, 165); 
        bottom:-$stroke; 
        right: -$stroke;
        @include border-animation( width, ease, 1);
    }
    .button-style--v1-wrap-inner:before {
        position: absolute; 
        width: $stroke; 
        height:0;content: ''; 
        background: rgb(46, 127, 165); 
        bottom:-$stroke; 
        left: -$stroke; 
        @include border-animation( height, ease, 0); 
        
    }
    &:hover{
        border-color:transparent;
        background:darken($primary_color,30);
        &:before{
            width: $pipeline_width; 
            @include border-animation( width, ease, 0); 
        }
        &:after {
            height:$pipeline_height; 
            @include border-animation( height, ease, 1); 
        }
        .button-style--v1-wrap-inner:after {
            width: $pipeline_width; 
            @include border-animation( width, ease, 2);
        }
        .button-style--v1-wrap-inner:before {
            height:$pipeline_height; 
            @include border-animation( height, ease, 3);
        }
    }
}