
.service-offerings--backgroundImg {
    // background-image: url('../../../../public/assets/bv-services-bg.png');
    padding: 100px 0;
    // background-color: #f2f4f7;
    background-color: #f7f7f7;
}

.service-offerings--background{
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 200px;

    .service-offerings--section{
        height: auto;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .alice-carousel{
            display: flex;
            flex-direction: column-reverse;
            .alice-carousel__dots{
                border-bottom: 1px solid #9900ff;
                display: flex;
                justify-content: center;
                .alice-carousel__dots-item{
                    .service-offerings--data-style{
                        display: flex;
                        justify-content: center;
                        height: 135px;
                        font-weight: normal;
                        font-family: 'Poppins-SemiBold';
                        font-size: 12px;
                        margin-right: 20px;
                        padding: 0 10px;
                        border-radius: 10px 10px 0 0;
                        background-color: #fff;
                        cursor: pointer;
                        &[data-style='true']{
                            // font-weight: bold;
                            color: white;
                            background: linear-gradient(90deg, #9900ff 0%, #b75df3 100%);
                            .service-offerings--data-text{
                             .service-offerings--data-svg{
                                path{
                                    stroke: #FFFFFF;
                                }
                             }   
                            }
                        }
                        &:hover{
                            color: white;
                            background: linear-gradient(90deg, #9900ff 0%, #b75df3 100%);
                            transition: all 1s ease;
                            .service-offerings--data-text{
                                .service-offerings--data-svg{
                                   path{
                                       stroke: #FFFFFF;
                                   }
                                }   
                            }
                        }
                        .service-offerings--data-text{
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            align-items: center;
                            margin-bottom: 20px;
                            .service-offerings--data-svg{
                                height: 26px;
                                width: 26px;
                                path{
                                    stroke: #9900ff;
                                }
                            }
                            .service-offerings--data-title{
                                height: 54px;
                            }
                        }
                    }
                }
            }
        }

        .service-offerings--main-container{
            height: auto;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-start;
            
            .service-offerings--main-container-inner {
                height: 2000px;
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                
                .service-offerings--container-caption{
                    height: auto;
                    width: 100%;
                    padding: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    background: rgba(255, 255, 255, 0.02);
                    backdrop-filter: blur(8px);
                    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
                    border-radius: 25px;
                    transition: all 0.9s;
                    .hero-left-content-action-item-call{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: 'Poppins-Medium', sans-serif;
                        width: auto;
                        // margin-left: 15px;
                        border-radius: 8px;
                        margin-top: 20px;
                        background: linear-gradient(90deg, #4A52F7 0%, #4a75f7 100%);
                        color: #fff;
                        &:hover {
                            background: white;
                            border: 1px solid #4A52F7;
                            color: #22195e;
                            transition: all 0.8s;
                        }

                        a {
                            // padding: 10px 25px;
                            font-size: 1.2rem;
                        }
                    }
                }
            }
            .service-offerings--container-img{
                width: auto;
                -webkit-user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                top: 30vh;
                position: sticky;
                margin-top: 100px;
                padding-bottom: 150px;
                img {
                    height: 400px;
                    animation-name: img360;
                    animation-duration: 5s;
                    animation-delay: 0;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                }

            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 450px) {
    .service-offerings--background {
        padding: 50px 0;
        .service-offerings--section {
            // sectiontitle
            width: 80%;

            .service-offerings--main-container {
                .service-offerings--main-container-inner {
                    height: auto;
                    width: 100%;
                    gap: 20px;

                    .service-offerings--container-caption {
                        // sective title v2
                        padding: 10px;
                        border-radius: 10px;

                        .hero-left-content-action-item-call {
                            padding: 8px 25px;
                            margin: 0 0 10px 15px;

                            a {
                                font-size: 0.5rem !important;
                            }
                        }

                    }
                }

                .service-offerings--container-img {
                    img {
                        // height: 200px;
                        display: none;
                    }
                }
            }

        }
    }
}

@media only screen and (min-width: 450px) and (max-width: 750px) {
    .service-offerings--background {
        padding: 50px 0;
        .service-offerings--section {
            // sectiontitle
            width: 80%;

            .service-offerings--main-container {
                .service-offerings--main-container-inner {
                    height: auto;
                    width: 100%;
                    gap: 20px;

                    .service-offerings--container-caption {
                        // sective title v2
                        padding: 10px;
                        border-radius: 10px;

                        .hero-left-content-action-item-call {
                            margin: 0 0 10px 15px;
                            padding: 8px 25px;

                            a {
                                font-size: 0.6rem !important;
                            }
                        }

                    }
                }

                .service-offerings--container-img {
                    img {
                        // height: 200px;
                        display: none;
                    }
                }
            }

        }
    }
}

@media only screen and (min-width: 750px) and (max-width: 900px) {
    .service-offerings--background {
        padding: 50px 0;
        .service-offerings--section {
            // sectiontitle
            width: 70%;

            .service-offerings--main-container {
                .service-offerings--main-container-inner {
                    height: auto;
                    width: 100%;
                    gap: 20px;

                    .service-offerings--container-caption {
                        // sective title v2
                        padding: 10px;
                        border-radius: 10px;

                        .hero-left-content-action-item-call {
                            padding: 8px 25px;
                            margin: 0 0 10px 15px;

                            a {
                                font-size: 0.6rem !important;
                            }
                        }

                    }
                }

                .service-offerings--container-img {
                    img {
                        // height: 200px;
                        display: none;
                    }
                }
            }

        }
    }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
    .service-offerings--background {
        padding: 50px 0;
        .service-offerings--section {
            // sectiontitle
            width: 90%;

            .service-offerings--main-container {

                .service-offerings--main-container-inner {
                    height: auto;
                    gap: 20px;
                    margin-top: 50px;

                    .service-offerings--container-caption {
                        // sective title v2
                        padding: 10px;
                        margin-bottom: 150px;
                        border-radius: 10px;

                        .hero-left-content-action-item-call {
                            margin-top: 0;
                            padding: 8px 25px !important;

                            a {
                                font-size: 0.6rem !important;
                            }
                        }

                    }
                }

                .service-offerings--container-img {
                    top: 25vh;
                    margin-top: 0px;
                    padding-bottom: 130px;
                    img {
                        height: 300px;
                        // display: none;
                    }
                }
            }

        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .service-offerings--background {
        padding: 50px 0;
        .service-offerings--section {
            // sectiontitle
            width: 75%;

            .service-offerings--main-container {
                .service-offerings--main-container-inner {
                    height: auto;
                    width: auto;
                    gap: 20px;
                    padding: 50px 20px;
                    margin-top: 50px;

                    .service-offerings--container-caption {
                        // sective title v2
                        height: 270px;
                        width: 470px;
                        padding: 30px 40px;
                        margin-bottom: 230px;
                        border-radius: 25px;
                        gap: 20px;

                        .hero-left-content-action-item-call {
                            margin: 0 0 10px 10px;

                            a {
                                font-size: 0.9rem;
                                padding: 8px 23px;
                            }
                        }

                    }
                }

                .service-offerings--container-img {
                    top: 25vh;
                    margin-top: 0px;
                    padding-bottom: 130px;
                    img {
                        height: 300px;
                        // display: none;
                    }
                }
            }

        }
    }
}

@media only screen and (min-width: 1600px) and (max-width: 2000px) {
    .service-offerings--container-caption {
        // sective title v2
        .hero-left-content-action-item-call {
            a {
                font-size: 1.2rem !important;
                padding: 8px 23px;
            }
        }

    }
}
