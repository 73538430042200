#services-features-outer-container {
  height: auto;
  width: 100%;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  #services-features-inner-container {
    height: auto;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(8px);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    border-radius: 25px;
    padding: 50px;

    #accordion-section {
      height: auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #282828;
      gap: 30px;

      #accordion-header-section {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 15px;

        #accordion-title {
          width: 60%;
          font-family: 'poppins-semibold', sans-serif;
          font-size: 3rem;
          line-height: 50px;
        }

        #accordion-desc {
          width: 50%;
          font-family: 'poppins', sans-serif;
          font-size: 1rem;
        }
      }

      #accordion-outer-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        gap: 5px;

        .accordion-inner-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .accordion-container {
            width: 500px;
            padding: 10px;
            border-radius: 20px;
            margin: 10px 5px;
    
            .accordion-summary {
              .accordion-question {
                font-size: 1.2rem;
                font-family: 'Poppins', sans-serif;
              }
            }
    
            .expandIcon {
              height: 15px !important;
              width: 50px;
              max-width: 50px;
            }
    
            .accordion-details {
              .accordion-answer {
                font-size: 1.1rem;
                font-family: 'Poppins', sans-serif;
              }
            }
          }
        }
      }
    }
  }
}

// media lets go
@media only screen and (min-width: 320px) and (max-width: 450px) {
  #services-features-outer-container {
    padding: 30px 0px;

    #services-features-inner-container {
      border-radius: 12px;
      padding: 30px 0;
      width: 90%;

      #accordion-section {
        align-items: center;

        #accordion-header-section {
          #accordion-title {
            width: 85%;
            font-size: 1.5rem;      
            line-height: normal;      
          }

          #accordion-desc {
            width: 85%;
            font-size: 0.8rem;      
          }
        }

        #accordion-outer-container {
          .accordion-inner-container {
            .accordion-container {
              width: 90%;
              padding: 5px;
              .accordion-summary {
                .accordion-question {
                  font-size: 0.9rem;
                }
              }
    
              .accordion-details {
                .accordion-answer {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 450px) and (max-width: 750px) {
  #services-features-outer-container {
    padding: 30px 0px;

    #services-features-inner-container {
      border-radius: 12px;
      padding: 30px 0;
      width: 90%;

      #accordion-section {
        width: 100%;
        align-items: center;

        #accordion-header-section {
          #accordion-title {
            width: 85%;
            font-size: 1.5rem;      
            line-height: normal;      
          }

          #accordion-desc {
            width: 85%;
            font-size: 0.8rem;      
          }
        }

        #accordion-outer-container {
          .accordion-inner-container {
            width: 100%;
            .accordion-container {
              width: 90%;
              .accordion-summary {
                .accordion-question {
                  font-size: 1.1rem;
                }
              }
    
              .accordion-details {
                .accordion-answer {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 750px) and (max-width: 950px) {
  #services-features-outer-container {
    padding: 30px 0px;

    #services-features-inner-container {
      border-radius: 12px;
      padding: 50px 0;
      width: 95%;

      #accordion-section {
        width: 100%;
        align-items: center;

        #accordion-header-section {
          #accordion-title {
            width: 60%;
            font-size: 1.7rem;
            line-height: normal;
          }

          #accordion-desc {
            width: 60%;
            font-size: 0.9rem;
          }
        }

        #accordion-outer-container {
          .accordion-inner-container {
            width: 100%;
            .accordion-container {
              width: 85%;
              .accordion-summary {
                .accordion-question {
                  font-size: 1.3rem;
                }
              }
    
              .accordion-details {
                .accordion-answer {
                  font-size: 1.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 950px) and (max-width: 1600px) {
  #services-features-outer-container {
    padding: 30px 0px;

    #services-features-inner-container {
      border-radius: 12px;
      padding: 50px 0;
      width: 80%;

      #accordion-section {
        width: 100%;

        #accordion-header-section {
          #accordion-title {
            width: 80%;
            font-size: 2rem;
          }

          #accordion-desc {
            width: 60%;
            font-size: 1.3rem;
          }
        }

        #accordion-outer-container {
          .accordion-inner-container {
            width: 100%;
            .accordion-container {
              width: 85%;
              .accordion-summary {
                .accordion-question {
                  font-size: 1.4rem;
                }
              }
    
              .accordion-details {
                .accordion-answer {
                  font-size: 1.3rem;
                }
              }
            }
          }
        }
      }
    }
  }
}