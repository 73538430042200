#whyUs-outer-container {
  height: auto;
  width: 100%;
  padding: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  #whyUs-inner-container {
    height: auto;
    width: 80%;
    padding: 15px;

    #whyUs-section-one {
      color: #282828;
      width: 80%;
      #whyUs-featMsg {
        font-family: 'poppins', sans-serif;
        font-size: 1rem;
        opacity: 0.8;
      }
      #whyUs-title {
        font-family: 'poppins-semibold', sans-serif;
        font-size: 2.5rem;
      }
      #whyUs-desc {
        font-family: 'poppins', sans-serif;
        width: 700px;
        font-size: 0.9rem;
        opacity: 0.8;
      }
    }

    #whyUs-section-two {
      height: auto;
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 50px;
      .item-container {
        height: 300px;
        width: 400px;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #282828;
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(8px);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
        border-radius: 25px;
        padding: 30px 40px;
        cursor: pointer;
        
        .item-icon-container {
          height: 75px;
          width: 75px;
          display: flex;
          justify-content: center;
          align-items: center;

          .item-icon {
            width: 100%;
          }
        }

        .item-title {
          font-family: 'poppins-semibold', sans-serif;
          font-size: 1.4rem;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .item-desc {
          text-align: center;
          font-family: 'poppins', sans-serif;
          font-size: 0.9rem;
          opacity: 0.8;
        }
      }
    }

  }
}

@media only screen and (min-width: 320px) and (max-width: 450px) {
  #whyUs-outer-container {
    padding: 20px;
    #whyUs-inner-container {
      width: 100%;
      #whyUs-section-one {
        #whyUs-featMsg {
          font-size: 0.7rem;
        }

        #whyUs-title {
          font-size: 1.1rem;
        }

        #whyUs-desc {
          width: 100%;
          font-size: 0.6rem;
        }
      }

      #whyUs-section-two {
        flex-direction: column;
        gap: 5px;
        .item-container {
          height: auto;
          width: auto;
          margin-top: 15px;
          padding: 20px;
          align-items: flex-start;
          .item-icon-container {
            height: 60px;
            width: 60px;
            .item-icon {
              height: 100px;
            }
          }

          .item-title {
            font-size: 1.1rem;
          }

          .item-desc {
            text-align: start;
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 450px) and (max-width: 900px) {
  #whyUs-outer-container {
    padding: 30px;
    #whyUs-inner-container {
      width: 95%;
      #whyUs-section-one {
        #whyUs-featMsg {
          font-size: 0.9rem;
        }

        #whyUs-title {
          font-size: 1.3rem;
        }

        #whyUs-desc {
          width: 100%;
          font-size: 0.8rem;
        }
      }

      #whyUs-section-two {
        flex-direction: column;
        gap: 0;
        .item-container {
          height: auto;
          width: 90%;
          margin-top: 20px;
          padding: 25px 30px;
          align-items: flex-start;
          .item-icon-container {
            height: 60px;
            width: 60px;
            .item-icon {
              width: 100%;
            }
          }

          .item-title {
            font-size: 1.3rem;
          }

          .item-desc {
            text-align: start;
            width: 100%;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  #whyUs-outer-container {
    padding: 40px;
    #whyUs-inner-container {
      width: 100%;
      #whyUs-section-one {
        #whyUs-featMsg {
          font-size: 0.9rem;
        }

        #whyUs-title {
          font-size: 1.3rem;
        }

        #whyUs-desc {
          width: 100%;
          font-size: 0.8rem;
        }
      }

      #whyUs-section-two {
        flex-direction: row;
        gap: 20px;
        .item-container {
          height: 300px;
          width: 290px;
          margin-top: 30px;
          padding: 20px 20px;
          .item-icon-container {
            height: 70px;
            width: 70px;
            .item-icon {
              width: 100%;
            }
          }

          .item-title {
            font-size: 1.1rem;
          }

          .item-desc {
            width: 100%;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  #whyUs-outer-container {
    padding: 100px 35px;
    #whyUs-inner-container {
      width: 85%;
      #whyUs-section-one {
        #whyUs-featMsg {
          font-size: 0.9rem;
        }

        #whyUs-title {
          font-size: 2.2rem;
        }

        #whyUs-desc {
          width: 100%;
          font-size: 0.9rem;
        }
      }

      #whyUs-section-two {
        flex-direction: row;
        gap: 30px;
        .item-container {
          height: 270px;
          width: 300px;
          margin-top: 40px;
          padding: 20px 20px;
          .item-icon-container {
            height: 75px;
            width: 75px;
            .item-icon {
              width: 100%;
            }
          }

          .item-title {
            font-size: 1.2rem;
          }

          .item-desc {
            width: 100%;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}