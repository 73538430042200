.main-site-home--page-wrap{
    opacity: 0;
    animation: faded 1s ease-in-out forwards;
}

@keyframes faded {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
    
}

//Responsive Traits Screen
@media only screen and (min-width: 1200px) and (max-width: 2700px) {
}


@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 460px) {
}