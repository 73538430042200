.borderline-container{
    position: relative;
    width: 131px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .borderline-line-bg{
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        border: 1px dashed #dbdade;
        z-index: -1;
    }
    .borderline-content{
        height: 92%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .borderline-sh2{
          margin-bottom: -10px;
          margin-top: 10px;  
        }
    }
}