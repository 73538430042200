
#highlights-outer-container {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  #city-scapes-bg {
    height: 400px;
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -150px;
    z-index: -1;
  
  #city-scapes-img {
      height: 400px;
      width: 1000px;
    }
  }

  #highlights-inner-container {
    width: 100%;
    padding-top: 150px;

    .highlights-container-style-two {
      flex-direction: row-reverse !important;
    }
    
    .highlights-container {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 100px;
      z-index: 0;
      position: relative;
      
      // remove if things does bad
      .highlights-outer-left-sectionn-style-two {
        justify-content: flex-start !important;
      }

      .highlights-outer-left-sectionn {
        height: auto;
        width: 900px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;

        // bubbles baby 
        .highlights-bubble-one {
          height: 70px;
          width: 70px;
          // background-color: rgba(#9900FF, 1);
          background-color: rgba(74, 82, 247, 0.7);
          position: absolute;
          border-radius: 100%;
          z-index: -1;
          top: -10%;
          left: 150px;
          animation: bubbleBounce 8s linear 0s infinite;
        }

        .highlights-inner-left-section {
          height: auto;
          width: 700px;
          border-radius: 30px;
          font-family: 'Poppins', sans-serif;
          background: rgba(255, 255, 255, 0.7);
          backdrop-filter: blur(2px);
          // box-shadow: 2px 2px 2px 2px rgba(75, 82, 247, 0.2);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          padding: 30px;
  
          .highlights-featMsg {
            font-size: 1.5rem;
            color: #4750FF;
            z-index: 3;
          }
      
          .highlights-title {
            font-family: 'Poppins-SemiBold', sans-serif;
            font-size: 2.5rem;
          }
      
          .highlights-cta {
            height: auto;
            width: 250px;
            padding: 10px 0;
            background-color: #4A52F7;
            font-size: 1.2rem;
            color: white;
            border-radius: 20px;
            text-align: center;
            margin-bottom: 10px;
          }
      
          .highlights-footerText {
            
          }
        }
      }

      .highlights-right-section {
        height: auto;
        width: auto;
        
        .highlights-img {
          width: 700px;
        }
      }

    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {

  #highlights-outer-container {
    #city-scapes-bg {
      #city-scapes-img {

      }
    }

    #highlights-inner-container {

      .highlights-container-style-two {

      }
    
      .highlights-container {
        .highlights-outer-left-sectionn {
          .highlights-bubble-one {

          }

          .highlights-bubble-two {

          }
          
          .highlights-inner-left-section {

            .highlights-featMsg {

            }

            .highlights-title {

            }

            .highlights-cta {

            }

            .highlights-footerText {

            }
          }

          .highlights-right-section {
            .highlights-img {

            }
          }
        } 
        .highlights-outer-left-sectionn-style-two {

        }
      }
    }
  }
}


@media only screen and (min-width: 320px) and (max-width: 500px) {

  #highlights-outer-container {
    #city-scapes-bg {
      width: 100%;
      #city-scapes-img {

      }
    }

    #highlights-inner-container {
      padding-top: 20px;

      .highlights-container-style-two {

      }
    
      .highlights-container {
        margin-bottom: 20px;

        .highlights-outer-left-sectionn-style-two {
          justify-content: flex-start !important;
        }

        .highlights-outer-left-sectionn {
          height: auto;
          width: auto;

          .highlights-bubble-one {
            height: 15px;
            width: 15px;
            top: -6px;
            left: -100px;
            animation-duration: 30s;
          }
          
          .highlights-inner-left-section {
            width: 150px;
            padding: 10px;
            gap: 2px;
            border-radius: 15px;

            .highlights-featMsg {
              font-size: 0.4rem;
            }

            .highlights-title {
              font-size: 0.5rem;
            }

            .highlights-cta {
              width: 50px;
              padding: 2px;
              font-size: 0.3rem;
              margin-bottom: 2px;
            }

            .highlights-footerText {
              font-size: 0.3rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 500px) and (max-width: 600px) {

  #highlights-outer-container {
    #city-scapes-bg {
      width: 100%;
      #city-scapes-img {

      }
    }

    #highlights-inner-container {
      padding-top: 20px;

      .highlights-container-style-two {

      }
    
      .highlights-container {
        margin-bottom: 20px;

        .highlights-outer-left-sectionn-style-two {
          justify-content: flex-start !important;
        }

        .highlights-outer-left-sectionn {
          height: auto;
          width: auto;

          .highlights-bubble-one {
            height: 15px;
            width: 15px;
            top: -6px;
            left: -100px;
            animation-duration: 30s;
          }
          
          .highlights-inner-left-section {
            width: 200px;
            padding: 15px;
            gap: 4px;
            border-radius: 15px;

            .highlights-featMsg {
              font-size: 0.6rem;
            }

            .highlights-title {
              font-size: 0.7rem;
            }

            .highlights-cta {
              width: 70px;
              padding: 3px;
              font-size: 0.4rem;
              margin-bottom: 3px;
            }

            .highlights-footerText {
              font-size: 0.4rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 750px) {

  #highlights-outer-container {
    #city-scapes-bg {
      width: 100%;
      #city-scapes-img {

      }
    }

    #highlights-inner-container {
      padding-top: 20px;

      .highlights-container-style-two {

      }
    
      .highlights-container {
        margin-bottom: 20px;

        .highlights-outer-left-sectionn-style-two {
          justify-content: flex-start !important;
        }

        .highlights-outer-left-sectionn {
          height: auto;
          width: auto;

          .highlights-bubble-one {
            height: 15px;
            width: 15px;
            top: -6px;
            left: -100px;
            animation-duration: 30s;
          }
          
          .highlights-inner-left-section {
            width: 250px;
            padding: 20px;
            gap: 4px;
            border-radius: 15px;

            .highlights-featMsg {
              font-size: 0.7rem;
            }

            .highlights-title {
              font-size: 0.8rem;
            }

            .highlights-cta {
              width: 70px;
              padding: 3px;
              font-size: 0.5rem;
              margin-bottom: 3px;
            }

            .highlights-footerText {
              font-size: 0.5rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 750px) and (max-width: 900px) {

  #highlights-outer-container {
    #city-scapes-bg {
      width: 100%;
      #city-scapes-img {

      }
    }

    #highlights-inner-container {
      padding-top: 20px;

      .highlights-container-style-two {

      }
    
      .highlights-container {
        margin-bottom: 20px;

        .highlights-outer-left-sectionn-style-two {
          justify-content: flex-start !important;
        }

        .highlights-outer-left-sectionn {
          height: auto;
          width: auto;

          .highlights-bubble-one {
            height: 15px;
            width: 15px;
            top: -6px;
            left: -100px;
            animation-duration: 30s;
          }
          
          .highlights-inner-left-section {
            width: 320px;
            padding: 25px;
            gap: 4px;
            border-radius: 15px;

            .highlights-featMsg {
              font-size: 0.7rem;
            }

            .highlights-title {
              font-size: 0.9rem;
            }

            .highlights-cta {
              width: 70px;
              padding: 3px;
              font-size: 0.6rem;
              margin-bottom: 3px;
            }

            .highlights-footerText {
              font-size: 0.6rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {

  #highlights-outer-container {
    #city-scapes-bg {
      width: 100%;
      #city-scapes-img {

      }
    }

    #highlights-inner-container {
      padding-top: 50px;

      .highlights-container-style-two {

      }
    
      .highlights-container {
        margin-bottom: 20px;

        .highlights-outer-left-sectionn-style-two {
          justify-content: flex-start !important;
        }

        .highlights-outer-left-sectionn {
          height: auto;
          width: auto;

          .highlights-bubble-one {
            height: 15px;
            width: 15px;
            top: -6px;
            left: -100px;
            animation-duration: 30s;
          }
          
          .highlights-inner-left-section {
            width: 420px;
            padding: 25px;
            gap: 6px;
            border-radius: 15px;

            .highlights-featMsg {
              font-size: 1rem;
            }

            .highlights-title {
              font-size: 1.2rem;
            }

            .highlights-cta {
              width: 150px;
              padding: 4px;
              font-size: 0.9rem;
              margin-bottom: 3px;
            }

            .highlights-footerText {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {

  #highlights-outer-container {
    #city-scapes-bg {
      width: 100%;
      #city-scapes-img {

      }
    }

    #highlights-inner-container {
      padding-top: 100px;

      .highlights-container-style-two {

      }
    
      .highlights-container {
        margin-bottom: 80px;

        .highlights-outer-left-sectionn-style-two {
          justify-content: flex-start !important;
          padding: 0 100px 0 50px !important;
        }

        .highlights-outer-left-sectionn {
          height: auto;
          width: auto;
          padding-left: 100px;
          padding-right: 50px;

          .highlights-bubble-one {
            height: 15px;
            width: 15px;
            top: -6px;
            left: -100px;
            animation-duration: 30s;
          }
          
          .highlights-inner-left-section {
            width: 550px;
            padding: 30px;
            gap: 6px;
            border-radius: 15px;

            .highlights-featMsg {
              font-size: 1.25rem;
            }

            .highlights-title {
              font-size: 2rem;
            }

            .highlights-cta {
              width: 180px;
              padding: 4px;
              font-size: 1.1rem;
              margin-bottom: 3px;
            }

            .highlights-footerText {
              font-size: 0.9rem;
            }
          }
        }

        .highlights-right-section {

          .highlights-img {
            width: 600px;
          }
        }
      }
    }
  }
}

// media quries for right section images
@media only screen and (min-width: 320px) and (max-width: 400px) {
  #highlights-outer-container {
    #highlights-inner-container {
      .highlights-container {

        .highlights-outer-left-sectionn-style-two {
          padding: 0 30px 0 20px !important;
        }

        .highlights-outer-left-sectionn {
          padding: 0 20px 0 30px;
          .highlights-inner-left-section {
          }
        }

        .highlights-right-section {

          .highlights-img {
            width: 150px;
          }
        }
      }
    }
  }
}

// media quries for left and right sections
@media only screen and (min-width: 400px) and (max-width: 500px) {
  #highlights-outer-container {
    #highlights-inner-container {
      .highlights-container {

        .highlights-outer-left-sectionn-style-two {
          padding: 0 60px 0 30px !important;
        }

        .highlights-outer-left-sectionn {
          padding: 0 30px 0 60px;
          .highlights-inner-left-section {
          }
        }

        .highlights-right-section {

          .highlights-img {
            width: 210px;
          }
        }
      }
    }
  }
}

// media quries for left and right sections
@media only screen and (min-width: 500px) and (max-width: 600px) {
  #highlights-outer-container {
    #highlights-inner-container {
      .highlights-container {

        .highlights-outer-left-sectionn-style-two {
          padding: 0 40px 0 20px !important;
        }

        .highlights-outer-left-sectionn {
          padding: 0 20px 0 40px;
          .highlights-inner-left-section {
          }
        }

        .highlights-right-section {

          .highlights-img {
            width: 250px;
          }
        }
      }
    }
  }
}

// media quries for left and right sections
@media only screen and (min-width: 600px) and (max-width: 750px) {
  #highlights-outer-container {
    #highlights-inner-container {
      .highlights-container {

        .highlights-outer-left-sectionn-style-two {
          padding: 0 70px 0 30px !important;
        }

        .highlights-outer-left-sectionn {
          padding: 0 30px 0 70px;
          .highlights-inner-left-section {
          }
        }

        .highlights-right-section {

          .highlights-img {
            width: 350px;
          }
        }
      }
    }
  }
}

// media quries for left and right sections
@media only screen and (min-width: 750px) and (max-width: 900px) {
  #highlights-outer-container {
    #highlights-inner-container {
      .highlights-container {

        .highlights-outer-left-sectionn-style-two {
          padding: 0 70px 0 30px !important;
        }

        .highlights-outer-left-sectionn {
          padding: 0 30px 0 70px;
          .highlights-inner-left-section {
          }
        }

        .highlights-right-section {

          .highlights-img {
            width: 370px;
          }
        }
      }
    }
  }
}

// media quries for left and right sections
@media only screen and (min-width: 900px) and (max-width: 1050px) {
  #highlights-outer-container {
    #highlights-inner-container {
      .highlights-container {

        .highlights-outer-left-sectionn-style-two {
          padding-right: 80px !important;
          padding-left: 30px !important;
        }

        .highlights-outer-left-sectionn {
          padding-left: 80px;
          padding-right: 30px;
          .highlights-inner-left-section {
          }
        }

        .highlights-right-section {

          .highlights-img {
            width: 430px;
          }
        }
      }
    }
  }
}

// media quries for left and right sections
@media only screen and (min-width: 1050px) and (max-width: 1200px) {
  #highlights-outer-container {
    #highlights-inner-container {
      .highlights-container {

        .highlights-outer-left-sectionn-style-two {
          padding: 0 150px 0 50px !important;
        }

        .highlights-outer-left-sectionn {
          padding: 0 50px 0 150px;
        }

        .highlights-right-section {
          .highlights-img {
            width: 450px;
          }
        }
      }
    }
  }
}

// bounce bounce baby
@keyframes bubbleBounce {
  0% {
    transform: translate(150px);
  }

  50% {
    transform: translate(200px);
  }

  100% {
    transform: translate(150px);
  }
}

// bounce bounce baby
@keyframes bubbleBouncee {
  0% {
    transform: translate(10px, -20px);
  }

  50% {
    transform: translate(10px, 5px);
  }

  100% {
    transform: translate(10px, -20px);
  }
}