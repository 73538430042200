.hero-wrap{
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 200px 0 0 0;
    position: relative;

    #blobTwo {
        height: 100%;
        width: 100%;
        position: absolute;
        animation-name: animationYehey;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(to right, #4DA4FC, #CD65E3);
        z-index: -999;
        border-radius: 25px;
    }

    .hero-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .hero-left-content{
            width: 80%;
            font-family: 'Poppins-Bold', sans-serif;
            color: #282828;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .hero-left-content-wrap{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;

                .hero-left-content-title{
                    width: 100%;
                    text-align: center;
                    font-size: 5rem;
                    margin-bottom: 15px;
                    line-height: 90px;

                    span {
                        background: linear-gradient(to right, #4A52F7, #3A0F74);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
                
                .hero-left-content-desc{
                    height: auto;
                    width: 50%;
                    font-family: 'Poppins-Medium', sans-serif;
                    font-size: 1rem;
                    text-align: center;
                }
            }
        }
        .hero-left-content-action-items{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 30px;
            gap: 20px;
            .hero-left-content-action-item-call{
                border-radius: 13px;
                font-size: 1.2rem;
                font-family: 'Poppins-SemiBold', sans-serif;
                padding: 15px 20px;
                width: 169px;
                background: rgba(255, 255, 255, 0.1);
                color: #4A52F7;
                &:hover{
                    background-color: #4A52F7;
                    color: #fff;
                    transition: all 0.5s ease;
                }
            }
            .hero-left-content-action-item-play{
                .hero-left-content-action-item-calll {
                    font-size: 1.2rem;
                    padding: 15px 20px;
                    background-color: #4A52F7;
                    font-family: 'Poppins', sans-serif;
                    border-radius: 13px;
                    color: #fff;
                    &:hover {
                        border: 1px solid #4A52F7;
                        background-color: #fff;
                        color: #4A52F7;
                        transition: all 0.5s ease;
                    }
                }
            }
            .hero-left-content-action-item-watch{
                margin: 0 30px;
                font-size: 12px;
                font-family: 'Poppins-Medium', sans-serif;
            }
        }

        .hero-right-content {
            height: auto;
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            #hero-right-img-container {
                width: 100%;
                position: relative;
    
                #hero-img-one {
                    width: 100%;
                }
    
                #hero-img-two {
                    height: 300px;
                    position: absolute;
                    right: 0;
                    top: -100px;
                    z-index: 2;
                }

                #hero-img-three {
                    height: 150px;
                    position: absolute;
                    bottom: 100px;
                    left: 100px;
                }

                #hero-img-four {
                    width: 70%;
                    position: absolute;
                    top: 100px;
                    left: 15%;
                }

                .hero-small-CTA {
                    height: auto;
                    width: auto;
                    padding: 8px 40px;
                    color: white;
                    position: absolute;
                    background-color: #4B5BB6;
                    font-size: 0.9rem;
                    border-radius: 10px;
                    font-family: 'poppins-semibold', sans-serif;
                }
                
                #hero-small-CTA-one {
                    top: 70px;
                    left: 260px;
                }
                #hero-small-CTA-two {
                    top: 70px;
                    left: 420px;
                }
                #hero-small-CTA-three {
                    top: 15px;
                    right: 250px;
                    z-index: 4;
                }

                #hero-president-container {
                    height: auto;
                    width: 400px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border-radius: 15px;
                    background: rgba(255, 255, 255, 0.4);
                    backdrop-filter: blur(8px);
                    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
                    padding: 10px;
                    gap: 10px;
                    position: absolute;
                    top: -60px;
                    left: 40px;

                    #hero-president-title {
                        height: auto;
                        font-family: 'poppins', sans-serif;
                        h1 {
                            font-size: 0.8rem;
                        }
    
                        h2 {
                            font-size: 0.7rem;
                        }
                    }
                }
            }
        }
    }
}


.iframe_inactive{
    position: absolute;
    // display: none;
    opacity: 0;
}

.overlay_active{
    display: block;
    position: fixed;
    content: '';
    top: 0;
    left: 0;
    background: rgba(0,0,0, 0.5);
    height: 100vh;
    width: 100vw;
    z-index: 999;
    transition: all 1s ease;
}

.iframe_active{
    position: fixed;
    z-index: 999;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 58vh;
    display: block;
    opacity: 1;
    transition: all 1s ease;    
    // border: 2px solid red;
}

// animation yehey
@keyframes animationYehey {
    0% {
        // left: 45%;
        // transform: translate(-0%, -0%);
    }
    50% {
        // left: 55%;
        // transform: translate(-2%, 2%);
    }
    100% {
        // left: 45%;
        // transform: translate(-0%, -0%);
    }
}

//Responsive Traits Screen
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .hero-wrap {
        padding-top: 170px;
        .hero-container {
            .hero-left-content {
                width: 70%;
                .hero-left-content-wrap {
                    .hero-left-content-title {
                        font-size: 3.2rem;
                        line-height: 60px;
                    }
                    .hero-left-content-desc {
                        width: 70%;
                        font-size: 0.9rem;
                    }
                }
            }

            .hero-right-content {
                height: 600px;
                margin-top: 80px;
                #hero-right-img-container {
                    #blobTwo {

                    }

                    #hero-img-one {

                    }

                    #hero-img-two {
                        height: 250px;
                    }

                    #hero-img-three {
                        height: 120px;
                        bottom: 80px;
                    }

                    #hero-img-four {
                        width: 60%;
                        left: 15%;
                        top: 80px;
                    }

                    .hero-small-CTA {
                        padding: 7px 35px;
                        font-size: 0.8rem;
                        border-radius: 8px;
                    }

                    #hero-small-CTA-one {
                        left: 205px;
                        top: 50px;
                    }

                    #hero-small-CTA-two {
                        left: 350px;
                        top: 50px;
                    }

                    #hero-small-CTA-three {
                        right: 200px;
                    }

                    #hero-president-container {
                        width: 350px;
                        top: -70px;
                        left: 20px;
                        
                        // president img 

                        #hero-president-title {
                            h1 {
                                font: 0.7rem;
                            }

                            h2 {
                                font: 0.6rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 999px) and (max-width: 1200px) {
    .hero-wrap {
        padding-top: 170px;
        .hero-container {
            .hero-left-content {
                width: 70%;
                .hero-left-content-wrap {
                    .hero-left-content-title {
                        width: 90%;
                        font-size: 2.5rem;
                        letter-spacing: 0;
                        line-height: 40px;
                    }
                    .hero-left-content-desc {
                        width: 80%;
                        font-size: 0.8rem;
                    }
                }
            }

            .hero-right-content {
                height: 600px;
                margin-top: 50px;
                #hero-right-img-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    #blobTwo {

                    }

                    #hero-img-one {
                        width: 100%;
                    }

                    #hero-img-two {
                        height: 210px;
                    }

                    #hero-img-three {
                        height: 100px;
                        bottom: 60px;
                        left: 80px;
                    }

                    #hero-img-four {
                        width: 60%;
                        left: 140px;
                        top: 80px;
                    }

                    .hero-small-CTA {
                        padding: 7px 30px;
                        font-size: 0.7rem;
                        border-radius: 7px;
                    }

                    #hero-small-CTA-one {
                        left: 200px;
                        top: 50px;
                    }

                    #hero-small-CTA-two {
                        left: 350px;
                        top: 50px;
                    }

                    #hero-small-CTA-three {
                        right: 170px;
                    }

                    #hero-president-container {
                        width: 320px;
                        top: -70px;
                        left: 20px;
                        
                        // president img 
                        #hero-president-img {
                            width: 35px;
                        }

                        #hero-president-title {

                            h1 {
                                font: 0.7rem;
                            }

                            h2 {
                                font: 0.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 750px) and (max-width: 999px) {
    .hero-wrap {
        padding-top: 170px;
        .hero-container {
            .hero-left-content {
                width: 80%;
                .hero-left-content-wrap {
                    .hero-left-content-title {
                        width: 90%;
                        font-size: 2.1rem;
                        letter-spacing: 0;
                        line-height: 40px;
                    }
                    .hero-left-content-desc {
                        width: 75%;
                        font-size: 0.8rem;
                    }
                }
            }

            .hero-right-content {
                height: 400px;
                margin-top: 40px;
                #hero-right-img-container {
                    width: 90%;

                    #blobTwo {

                    }

                    #hero-img-one {
                        width: 100%;
                    }

                    #hero-img-two {
                        height: 190px;
                    }

                    #hero-img-three {
                        height: 80px;
                        bottom: 50px;
                        left: 70px;
                    }

                    #hero-img-four {
                        width: 60%;
                        left: 120px;
                        top: 60px;
                    }
                }
            }
        }
    }
}

// for small CTA
@media only screen and (min-width: 850px) and (max-width: 999px) {
    .hero-wrap {
        .hero-container {
            .hero-right-content {
                #hero-right-img-container {
                    .hero-small-CTA {
                        padding: 6px 20px;
                        font-size: 0.5rem;
                        border-radius: 7px;
                    }

                    #hero-small-CTA-one {
                        left: 180px;
                        top: 35px;
                    }

                    #hero-small-CTA-two {
                        left: 310px;
                        top: 35px;
                    }

                    #hero-small-CTA-three {
                        right: 150px;
                        top: 5px;
                    }

                    #hero-president-container {
                        width: 300px;
                        top: -60px;
                        left: 20px;
                        padding: 5px;
                        
                        // president img 
                        #hero-president-img {
                            width: 30px;
                        }

                        #hero-president-title {
                            h1 {
                                font-size: 0.7rem;
                            }

                            h2 {
                                font-size: 0.6rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

// for small CTA
@media only screen and (min-width: 700px) and (max-width: 850px) {
    .hero-wrap {
        .hero-container {
            .hero-right-content {
                #hero-right-img-container {
                    .hero-small-CTA {
                        padding: 6px 20px;
                        font-size: 0.5rem;
                        border-radius: 7px;
                    }

                    #hero-small-CTA-one {
                        left: 150px;
                        top: 35px;
                    }

                    #hero-small-CTA-two {
                        left: 250px;
                        top: 35px;
                    }

                    #hero-small-CTA-three {
                        right: 150px;
                        top: 5px;
                    }

                    #hero-president-container {
                        width: 250px;
                        top: -50px;
                        left: 20px;
                        padding: 5px;
                        
                        // president img 
                        #hero-president-img {
                            width: 25px;
                        }

                        #hero-president-title {
                            h1 {
                                font-size: 0.7rem;
                            }

                            h2 {
                                font-size: 0.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

// for small CTA
@media only screen and (min-width: 450px) and (max-width: 700px) {
    .hero-wrap {
        .hero-container {
            .hero-right-content {
                #hero-right-img-container {
                    
                    .hero-small-CTA {
                        padding: 6px 25px;
                        font-size: 0.5rem;
                        border-radius: 7px;
                    }

                    #hero-small-CTA-one {
                        // left: 150px;
                        // top: 35px;
                        left: 20%;
                        top: 10%;
                    }

                    #hero-small-CTA-two {
                        left: 35%;
                        top: 10%;
                    }

                    #hero-small-CTA-three {
                        right: 20%;
                        top: 0.5%;
                    }

                    #hero-president-container {
                        width: 250px;
                        top: -50px;
                        left: 20px;
                        padding: 5px;
                        
                        // president img 
                        #hero-president-img {
                            width: 25px;
                        }

                        #hero-president-title {
                            h1 {
                                font-size: 0.7rem;
                            }

                            h2 {
                                font-size: 0.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

// for small CTA
@media only screen and (min-width: 320px) and (max-width: 450px) {
    .hero-wrap {
        .hero-container {
            .hero-right-content {
                #hero-right-img-container {
                    .hero-small-CTA {
                        padding: 2px 15px;
                        font-size: 0.4rem;
                        border-radius: 6px;
                    }

                    #hero-small-CTA-one {
                        // left: 150px;
                        // top: 35px;
                        left: 20%;
                        top: 10%;
                    }

                    #hero-small-CTA-two {
                        left: 40%;
                        top: 10%;
                    }

                    #hero-small-CTA-three {
                        right: 20%;
                        top: 0.5%;
                    }

                    #hero-president-container {
                        height: 30px;
                        width: 180px;
                        top: -25px;
                        left: 5px;
                        padding: 5px;
                        gap: 5px;
                        border-radius: 10px;
                        
                        // president img 
                        #hero-president-img {
                            width: 15px;
                        }

                        #hero-president-title {
                            h1 {
                                font-size: 0.4rem;
                            }

                            h2 {
                                font-size: 0.3rem;
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 400px) and (max-width: 750px) {
    .hero-wrap {
        padding-top: 140px;
        .hero-container {
            .hero-left-content {
                width: 80%;
                .hero-left-content-wrap {
                    .hero-left-content-title {
                        font-size: 1.9rem;
                        line-height: 40px;
                    }
                    .hero-left-content-desc {
                        width: 90%;
                        font-size: 0.7rem;
                    }
                }
            }

            .hero-right-content {
                height: auto;
                margin-top: 20px;
                padding-top: 50px;
                #hero-right-img-container {
                    width: 100%;
                    #blobTwo {
                        height: 100%;
                        width: 100%;
                    }

                    #hero-img-one {
                        width: 100%;
                    }

                    #hero-img-two {
                        height: 140px;
                        top: -20%;
                    }

                    #hero-img-three {
                        height: 70px;
                        bottom: 15%;
                        left: 7%;
                    }

                    #hero-img-four {
                        width: 70%;
                        left: 15%;
                        top: 18%;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
    .hero-wrap {
        padding-top: 120px;
        .hero-container {
            .hero-left-content {
                width: 100%;
                padding: 10px;
                .hero-left-content-wrap {
                    .hero-left-content-title {
                        font-size: 1.8rem;
                    }
                    .hero-left-content-desc {
                        width: 90%;
                        font-size: 0.6rem;
                    }
                }
            }

            .hero-right-content {
                height: auto;
                margin-top: 20px;
                #hero-right-img-container {
                    width: 100%;
                    #hero-img-one {

                    }

                    #hero-img-two {
                        height: 100px;
                        top: -40px;
                    }

                    #hero-img-three {
                        height: 50px;
                        bottom: 25px;
                        left: 40px;
                    }

                    #hero-img-four {
                        width: 65%;
                        left: 70px;
                        top: 40px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
    .hero-wrap {
        padding-top: 140px;
        .hero-container {

            .hero-left-content {
                width: 100%;
                padding: 10px;
                .hero-left-content-wrap {
                    .hero-left-content-title {
                        width: 95%;
                        font-size: 1.3rem;
                        line-height: 30px;
                    }
                    .hero-left-content-desc {
                        width: 80%;
                        font-size: 0.5rem;
                    }
                }
            }

            .hero-right-content {
                height: auto;
                margin-top: 30px;
                #hero-right-img-container {
                    width: 100%;
                    #blobTwo {
                        height: 180px;
                        width: 100%;
                        border-radius: 15px;
                    }

                    #hero-img-one {

                    }

                    #hero-img-two {
                        height: 80px;
                        top: -40px;
                    }

                    #hero-img-three {
                        height: 40px;
                        bottom: 25px;
                        left: 30px;
                    }

                    #hero-img-four {
                        width: 65%;
                        left: 60px;
                        top: 40px;
                    }
                }
            }
        }
    }
}

@keyframes pulse-linear {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(230, 110, 109, 0.7);
    }
    
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(230, 110, 109, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(230, 110, 109, 0);
    }
  }