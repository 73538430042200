#hris-service-outer-container {
  height: auto;
  width: 100%;
  padding-top: 150px;
  
  #hris-service-inner-container {
    height: auto;
    width: 100%;
    padding-bottom: 50px;
    
    #section-one {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 150px;

      #section-one-container-one {
        color: #282828;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        #section-one-featMsg {
          font-size: 1.4rem;
          font-family: 'poppins', sans-serif;
          opacity: 0.8;
        }

        #section-one-title {
          width: 70%;
          font-size: 3.5rem;
          font-family: 'poppins-semibold', sans-serif;
          line-height: 50px ;
        }

        #section-one-desc {
          font-size: 1.2rem;
          font-family: 'poppins', sans-serif;
          opacity: 0.8;
        }
      } 

      #section-one-container-two {
        height: 400px;
        width: 95%;
        background: linear-gradient(to right, #4DA4FC, #CD65E3);
        border-radius: 25px;
        position: relative;

        #section-one-inner-container-two {
          height: 400px;
          width: 100%;
          position: absolute;
          top: -70px;
          left: 50%;
          transform: translate(-50%);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          gap: 20px;

          #hris-image {
            height: 100%;
            width: 400px;
          }

          #hris-container {
            display: flex;
            flex-direction: column;
            gap: 20px;

            #hris-announcement {
              height: 100px;
              width: 100%;
              background: rgba(255, 255, 255, 0.4);
              backdrop-filter: blur(8px);
              box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
              border-radius: 25px;
              display: flex;
              flex-direction: row;
              padding-left: 20px;
              justify-content: flex-start;
              align-items: center;
              gap: 15px;

              #hris-announcement-profile {
                height: 70px;
              }

              #hris-announcement-texts {
                color: #282828;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                #hris-announcement-title {
                  font-family: 'poppins-semibold', sans-serif;
                  font-size: 1.1rem;
                }

                #hris-announcement-desc {
                  font-family: 'poppins', sans-serif;
                  font-size: 0.9rem;
                }
              }
            }

            #hris-user-container {
              display: flex;
              flex-direction: row;
              gap: 20px;

              .hris-user {
                height: 280px;
                width: auto;
                display: flex;
                justify-content: space-evenly;
                border-radius: 25px;
                color: #282828;
                background: rgba(255, 255, 255, 0.4);
                backdrop-filter: blur(8px);
                box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
                padding: 20px;
                flex-direction: column;
                gap: 15px;

                .hris-user-header-section {
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  gap: 15px;

                  .hris-user-profile {
                    height: 70px;
                  }

                  .hris-user-texts {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    .hris-user-name {
                      font-family: 'poppins-semibold', sans-serif;
                      font-size: 1.1rem;
                    }

                    .hris-user-position {
                      font-family: 'poppins', sans-serif;
                      font-size: 0.9rem;
                    }
                  }
                }

                .hris-user-body-section {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  font-family: 'poppins', sans-serif;
                  .hris-user-body-item-one {
                    opacity: 0.8;
                  }

                  .hris-user-body-item-two {
                    color: #4DA4FC;
                  }

                  .hris-user-body-item-three {
                    opacity: 0.8;
                  }
                }

                .hris-user-footer-section {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 20px;
                  font-family: 'poppins', sans-serif;
                  .hris-user-button-one {
                    padding: 10px 30px;
                    background-color: #4DA4FC; 
                    color: white;    
                    font-size: 0.9rem;    
                    border-radius: 8px;           
                  }

                  .hris-user-button-two {
                    padding: 10px 30px;
                    background-color: #4DA4FC; 
                    color: white;  
                    font-size: 0.9rem;
                    border-radius: 8px;           
                  }
                }
              }
            }
          }

          #hris-payroll-time-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            #hris-payroll {
              height: 200px;
              width: auto;
              border-radius: 25px;
            }

            #hris-timein-container {
              height: auto;
              width: 100%;
              background: rgba(255, 255, 255, 0.4);
              backdrop-filter: blur(8px);
              box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
              border-radius: 25px;
              display: flex;
              flex-direction: column;
              padding-left: 20px;
              justify-content: flex-start;
              align-items: center;
              gap: 15px;
              padding: 20px;

              #hris-timein-header-section {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 15px;

                #hris-timein-profile {
                  height: 70px;
                }
  
                #hris-timein-texts {
                  color: #282828;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  #hris-timein-date {
                    font-family: 'poppins-semibold', sans-serif;
                    font-size: 1.1rem;
                  }
  
                  #hris-timein-time {
                    font-family: 'poppins', sans-serif;
                    font-size: 0.9rem;
                  }
                }
              }

              #hris-timein-buttons {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                font-family: 'poppins', sans-serif;
                #hris-timein-button-one {
                  padding: 10px 30px;
                  background-color: #4DA4FC; 
                  color: white;    
                  font-size: 0.9rem;    
                  border-radius: 8px;          
                }

                #hris-timein-button-two {
                  padding: 10px 30px;
                  background-color: #4DA4FC; 
                  color: white;  
                  font-size: 0.9rem;
                  border-radius: 8px;           
                }
              }
            }
          }
        }
      }
    }

    #section-two {
      padding-top: 150px;
      color: #282828;
      #section-two-container-one {
        height: auto;
        width: 100%;
        padding: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        #section-two-header-container {
          width: 60%;
          text-align: center;
          #section-two-header-title {
            font-family: 'poppins-semibold', sans-serif;
            font-size: 3.5rem;
            line-height: 50px;
            margin-bottom: 15px;
          }

          #section-two-header-desc {
            font-family: 'poppins', sans-serif;
            font-size: 1.1rem;
            opacity: 0.8;
          }
        }
      }

      #section-two-container-two {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 60px;
        position: relative;

        #floater {
          height: 300px;
          width: 100%;
          background: linear-gradient(to right, #4DA4FC, #CD65E3);
          position: absolute;
          bottom: -200px;
          opacity: 40%;
        }

        .section-two-container-two-item {
          height: 450px;
          width: 350px;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #282828;
          text-align: center;
          padding: 50px 20px;
          gap: 15px;
          background: rgba(255, 255, 255, 0.02);
          backdrop-filter: blur(8px);
          box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
          border-radius: 25px;
          transition: all 1s linear;
          cursor: pointer;

          &:hover {
            background: rgba(255, 255, 255, 1);
          }

          .hris-item-img {
            height: 100px;
          }

          .hris-item-title {
            font-family: 'poppins-semibold', sans-serif;
            font-size: 1.8rem;
          }

          .hris-item-desc {
            font-family: 'poppins', sans-serif;
            font-size: 1rem;
            opacity: 0.8;
          }
        }

      }
    }

    #section-three {

    }
  }
}

// LFG
@media only screen and (min-width: 320px) and (max-width: 460px) {
  #hris-service-outer-container {
    #hris-service-inner-container {
      #section-one {
        gap: 60px;
        #section-one-container-one {
          #section-one-featMsg {
            font-size: 0.7rem;
          }

          #section-one-title {
            width: 90%;
            font-size: 1.5rem;
            line-height: 30px;
          }

          #section-one-desc {
            font-size: 0.7rem;
            width: 80%;
          }
        }

        #section-one-container-two {
          height: 320px;
          width: 100%;
          border-radius: 0;
          #section-one-inner-container-two {
            top: -30px;
            #hris-image {
              display: none;
            }

            #hris-container {
              gap: 15px;
              #hris-announcement {
                height: 60px;
                width: auto;
                border-radius: 15px;
                padding-left: 10px;
                margin: 0 5px;
                #hris-announcement-profile {
                  height: 35px;
                }

                #hris-announcement-texts {
                  width: 300px;
                  text-align: justify;

                  #hris-announcement-title {
                    font-size: 0.8rem;
                  }
                  
                  #hris-announcement-desc {
                    padding-right: 20px;
                    font-size: 0.6rem;
                  }
                }
              }

              #hris-user-container {
                gap: 8px;
                justify-content: center;
                
                .hris-user:first-child {
                  display: none;
                }

                .hris-user {
                  padding: 15px;
                  border-radius: 15px;
                  height: 250px;
                  .hris-user-header-section {
                    .hris-user-profile {
                      height: 50px;
                    }

                    .hris-user-texts {
                      .hris-user-name {
                        font-size: 1rem;
                      }

                      .hris-user-position {
                        font-size: 0.9rem;
                      }
                    }
                  }

                  .hris-user-body-section {
                    .hris-user-body-item-one, .hris-user-body-item-two, .hris-user-body-item-three {
                      font-size: 0.9rem;
                    }
                  }

                  .hris-user-footer-section {
                    gap: 15px;
                    flex-direction: row;
                    align-items: flex-start;
                    .hris-user-button-one {
                      padding: 10px 25px;
                      font-size: 0.8rem;
                    }
                    
                    .hris-user-button-two {
                      padding: 10px 25px;
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            }
            
            #hris-payroll-time-container {
              display: none;
            }
          }
        }
      }

      #section-two {
        padding-top: 40px;
        #section-two-container-one {
          padding: 20px;
          #section-two-header-container {
            width: 90%;
            #section-two-header-title {
              font-size: 1.5rem;
              line-height: 30px;
            }

            #section-two-header-desc {
              font-size: 0.7rem;
            }
          }
        }

        #section-two-container-two {
          gap: 15px;
          flex-direction: column;
          #floater {
            display: none;
          }

          .section-two-container-two-item {
            height: 200px;
            width: 300px;
            padding: 20px 40px;
            gap: 8px;
            .hris-item-img {
              height: 55px;
            }

            .hris-item-title {
              font-size: 1.2rem;
            }

            .hris-item-desc {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 460px) and (max-width: 650px) {
  #hris-service-outer-container {
    #hris-service-inner-container {
      #section-one {
        gap: 60px;
        #section-one-container-one {
          #section-one-featMsg {
            font-size: 0.8rem;
          }

          #section-one-title {
            width: 90%;
            font-size: 2rem;
            line-height: 40px;
          }

          #section-one-desc {
            font-size: 0.7rem;
            width: 70%;
          }
        }

        #section-one-container-two {
          height: 330px;
          width: 100%;
          border-radius: 0;
          #section-one-inner-container-two {
            top: -30px;
            #hris-image {
              display: none;
            }

            #hris-container {
              gap: 8px;
              #hris-announcement {
                height: 60px;
                width: auto;
                border-radius: 15px;
                #hris-announcement-profile {
                  height: 35px;
                }

                #hris-announcement-texts {
                  width: 300px;
                  text-align: justify;

                  #hris-announcement-title {
                    font-size: 0.8rem;
                  }
                  
                  #hris-announcement-desc {
                    padding-right: 20px;
                    font-size: 0.6rem;
                  }
                }
              }

              #hris-user-container {
                gap: 8px;
                .hris-user {
                  padding: 15px;
                  border-radius: 15px;
                  height: 250px;
                  .hris-user-header-section {
                    .hris-user-profile {
                      height: 35px;
                    }

                    .hris-user-texts {
                      .hris-user-name {
                        font-size: 0.8rem;
                      }

                      .hris-user-position {
                        font-size: 0.6rem;
                      }
                    }
                  }

                  .hris-user-body-section {
                    .hris-user-body-item-one, .hris-user-body-item-two, .hris-user-body-item-three {
                      font-size: 0.8rem;
                    }
                  }

                  .hris-user-footer-section {
                    gap: 10px;
                    flex-direction: row;
                    align-items: flex-start;
                    .hris-user-button-one {
                      padding: 10px 18px;
                      font-size: 0.6rem;
                    }
                    
                    .hris-user-button-two {
                      padding: 10px 18px;
                      font-size: 0.6rem;
                    }
                  }
                }
              }
            }
            
            #hris-payroll-time-container {
              display: none;
            }
          }
        }
      }

      #section-two {
        padding-top: 60px;
        #section-two-container-one {
          padding: 20px;
          #section-two-header-container {
            width: 95%;
            #section-two-header-title {
              font-size: 2rem;
              line-height: 40px;
            }

            #section-two-header-desc {
              font-size: 0.7rem;
            }
          }
        }

        #section-two-container-two {
          gap: 15px;
          flex-direction: column;
          #floater {
            display: none;
            height: 180px;
            bottom: -110px;
          }

          .section-two-container-two-item {
            height: 180px;
            width: 400px;
            padding: 20px 40px;
            gap: 8px;
            .hris-item-img {
              height: 55px;
            }

            .hris-item-title {
              font-size: 1.2rem;
            }

            .hris-item-desc {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 650px) and (max-width: 900px) {
  #hris-service-outer-container {
    #hris-service-inner-container {
      #section-one {
        gap: 60px;
        #section-one-container-one {
          #section-one-featMsg {
            font-size: 0.9rem;
          }

          #section-one-title {
            font-size: 2.2rem;
            line-height: 40px;
          }

          #section-one-desc {
            font-size: 0.7rem;
          }
        }

        #section-one-container-two {
          height: 330px;
          #section-one-inner-container-two {
            top: -30px;
            gap: 12px;
            #hris-image {
              height: 300px;
              width: 250px;
            }

            #hris-container {
              gap: 10px;
              #hris-announcement {
                height: 60px;
                width: auto;
                border-radius: 20px;
                #hris-announcement-profile {
                  height: 35px;
                }

                #hris-announcement-texts {
                  width: 300px;
                  text-align: justify;

                  #hris-announcement-title {
                    font-size: 0.8rem;
                  }
                  
                  #hris-announcement-desc {
                    padding-right: 20px;
                    font-size: 0.6rem;
                  }
                }
              }

              #hris-user-container {
                gap: 10px;
                .hris-user {
                  padding: 15px;
                  border-radius: 20px;
                  height: 250px;
                  align-items: center;
                  .hris-user-header-section {
                    .hris-user-profile {
                      height: 35px;
                    }

                    .hris-user-texts {
                      .hris-user-name {
                        font-size: 0.8rem;
                      }

                      .hris-user-position {
                        font-size: 0.6rem;
                      }
                    }
                  }

                  .hris-user-body-section {
                    .hris-user-body-item-one, .hris-user-body-item-two, .hris-user-body-item-three {
                      font-size: 0.8rem;
                    }
                  }

                  .hris-user-footer-section {
                    gap: 10px;
                    flex-direction: column;
                    align-items: flex-start;
                    .hris-user-button-one {
                      width: 150px;
                      padding: 10px 18px;
                      font-size: 0.6rem;
                    }
                    
                    .hris-user-button-two {
                      width: 150px;
                      padding: 10px 18px;
                      font-size: 0.6rem;
                    }
                  }
                }
              }
            }
            
            #hris-payroll-time-container {
              gap: 12px;
              align-items: flex-start;
              display: none;
              #hris-timein-container {
                padding: 15px;
                #hris-timein-header-section {
                  gap: 12px;
                  #hris-timein-profile {
                    height: 45px;
                  }

                  #hris-timein-texts {
                    #hris-timein-date {
                      font-size: 0.7rem;
                    }

                    #hris-timein-time {
                      font-size: 0.8rem;
                    }
                  }
                }

                #hris-timein-buttons {
                  gap: 15px;
                  flex-direction: column;
                  #hris-timein-button-one {
                    width: 150px;
                    padding: 10px 25px;
                    font-size: 0.7rem;
                  }

                  #hris-timein-button-two {
                    width: 150px;
                    padding: 10px 25px;
                    font-size: 0.7rem;
                  }
                }
              }
              #hris-payroll {
                height: 150px;
              }
            }
          }
        }
      }

      #section-two {
        padding-top: 60px;
        #section-two-container-one {
          padding: 20px;
          #section-two-header-container {
            width: 80%;
            #section-two-header-title {
              font-size: 2.2rem;
              line-height: 40px;
            }

            #section-two-header-desc {
              font-size: 0.7rem;
            }
          }
        }

        #section-two-container-two {
          gap: 10px;
          #floater {
            height: 180px;
            bottom: -110px;
          }

          .section-two-container-two-item {
            height: 320px;
            width: 210px;
            padding: 12px 12px;
            .hris-item-img {
              height: 55px;
            }

            .hris-item-title {
              font-size: 1.2rem;
            }

            .hris-item-desc {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  #hris-service-outer-container {
    #hris-service-inner-container {
      #section-one {
        gap: 100px;
        #section-one-container-one {
          #section-one-featMsg {
            font-size: 1rem;
          }

          #section-one-title {
            font-size: 2.7rem;
          }

          #section-one-desc {
            font-size: 0.9rem;
          }
        }

        #section-one-container-two {
          #section-one-inner-container-two {
            top: -50px;
            gap: 12px;
            #hris-image {
              width: 300px;
            }

            #hris-container {
              gap: 12px;
              #hris-announcement {
                height: 80px;
                width: auto;
                border-radius: 20px;
                #hris-announcement-profile {
                  height: 45px;
                }

                #hris-announcement-texts {
                  width: 300px;
                  text-align: justify;

                  #hris-announcement-title {
                    font-size: 0.9rem;
                  }
                  
                  #hris-announcement-desc {
                    padding-right: 20px;
                    font-size: 0.8rem;
                  }
                }
              }

              #hris-user-container {
                gap: 12px;
                .hris-user {
                  padding: 15px;
                  border-radius: 20px;
                  height: 250px;
                  align-items: center;
                  .hris-user-header-section {
                    .hris-user-profile {
                      height: 45px;
                    }

                    .hris-user-texts {
                      .hris-user-name {
                        font-size: 0.9rem;
                      }

                      .hris-user-position {
                        font-size: 0.7rem;
                      }
                    }
                  }

                  .hris-user-body-section {
                    .hris-user-body-item-one, .hris-user-body-item-two, .hris-user-body-item-three {
                      font-size: 0.9rem;
                    }
                  }

                  .hris-user-footer-section {
                    gap: 10px;
                    flex-direction: column;
                    align-items: flex-start;
                    .hris-user-button-one {
                      width: 150px;
                      padding: 10px 18px;
                      font-size: 0.7rem;
                    }
                    
                    .hris-user-button-two {
                      width: 150px;
                      padding: 10px 20px;
                      font-size: 0.7rem;
                    }
                  }
                }
              }
            }
            
            #hris-payroll-time-container {
              gap: 12px;
              align-items: flex-start;
              #hris-timein-container {
                padding: 15px;
                #hris-timein-header-section {
                  gap: 12px;
                  #hris-timein-profile {
                    height: 45px;
                  }

                  #hris-timein-texts {
                    #hris-timein-date {
                      font-size: 0.7rem;
                    }

                    #hris-timein-time {
                      font-size: 0.8rem;
                    }
                  }
                }

                #hris-timein-buttons {
                  gap: 15px;
                  flex-direction: column;
                  #hris-timein-button-one {
                    width: 150px;
                    padding: 10px 25px;
                    font-size: 0.7rem;
                  }

                  #hris-timein-button-two {
                    width: 150px;
                    padding: 10px 25px;
                    font-size: 0.7rem;
                  }
                }
              }
              #hris-payroll {
                height: 150px;
              }
            }
          }
        }
      }

      #section-two {
        padding-top: 60px;
        #section-two-container-one {
          padding: 40px;
          #section-two-header-container {
            #section-two-header-title {
              font-size: 2.7rem;
            }

            #section-two-header-desc {
              font-size: 0.9rem;
            }
          }
        }

        #section-two-container-two {
          gap: 30px;
          #floater {
            height: 180px;
            bottom: -110px;
          }

          .section-two-container-two-item {
            height: 300px;
            width: 290px;
            padding: 50px 25px;
            .hris-item-img {
              height: 55px;
            }

            .hris-item-title {
              font-size: 1.2rem;
            }

            .hris-item-desc {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  #hris-service-outer-container {
    #hris-service-inner-container {
      #section-one {
        gap: 130px;
        #section-one-container-one {
          #section-one-featMsg {
            font-size: 1.2rem;
          }

          #section-one-title {
            font-size: 3.3rem;
          }

          #section-one-desc {
            font-size: 1rem;
          }
        }

        #section-one-container-two {
          #section-one-inner-container-two {
            gap: 15px;
            #hris-image {
              width: 350px;
            }

            #hris-container {
              gap: 15px;
              #hris-announcement {
                height: 90px;
                border-radius: 20px;
                #hris-announcement-profile {
                  height: 55px;
                }

                #hris-announcement-texts {
                  #hris-announcement-title {
                    font-size: 1rem;
                  }
                  
                  #hris-announcement-desc {
                    font-size: 0.85rem;
                  }
                }
              }

              #hris-user-container {
                gap: 15px;
                .hris-user {
                  padding: 18px;
                  border-radius: 20px;
                  .hris-user-header-section {
                    .hris-user-profile {
                      height: 55px;
                    }

                    .hris-user-texts {
                      .hris-user-name {
                        font-size: 1rem;
                      }

                      .hris-user-position {
                        font-size: 0.8rem;
                      }
                    }
                  }

                  .hris-user-body-section {
                    .hris-user-body-item-one {

                    }

                    .hris-user-body-item-two {

                    }

                    .hris-user-body-item-three {

                    }
                  }

                  .hris-user-footer-section {
                    gap: 15px;
                    .hris-user-button-one {
                      padding: 10px 20px;
                      font-size: 0.8rem;
                    }
                    
                    .hris-user-button-two {
                      padding: 10px 20px;
                      font-size: 0.8rem;
                    }
                  }
                }
              }
            }
            
            #hris-payroll-time-container {
              gap: 15px;
              align-items: flex-start;
              #hris-timein-container {
                padding: 18px;
                #hris-timein-header-section {
                  gap: 12px;
                  #hris-timein-profile {
                    height: 55px;
                  }

                  #hris-timein-texts {
                    #hris-timein-date {
                      font-size: 1rem;
                    }

                    #hris-timein-time {
                      font-size: 0.8rem;
                    }
                  }
                }

                #hris-timein-buttons {
                  gap: 15px;
                  #hris-timein-button-one {
                    padding: 10px 25px;
                  }

                  #hris-timein-button-two {
                    padding: 10px 25px;
                  }
                }
              }
              #hris-payroll {
                height: 180px;
              }
            }
          }
        }
      }

      #section-two {
        padding-top: 100px;
        #section-two-container-one {
          padding: 40px;
          #section-two-header-container {
            #section-two-header-title {
              font-size: 3.3rem;
            }

            #section-two-header-desc {
              font-size: 1rem;
            }
          }
        }

        #section-two-container-two {
          gap: 40px;
          #floater {
            height: 200px;
            bottom: -120px;
          }

          .section-two-container-two-item {
            height: 350px;
            width: 320px;
            padding: 50px 25px;
            .hris-item-img {
              height: 70px;
            }

            .hris-item-title {
              font-size: 1.5rem;
            }

            .hris-item-desc {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}