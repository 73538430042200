
.contact-us--section-hero{
    position: absolute;
    z-index: -1;
    opacity: 0.4;
    // CLEAN THIS AFTER
    // width: 100vw;
    // height: 100vh;
    img{
        width: 100%;
        height: 100%;
        display: none;
    }
}

.modalDemo {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 0;
    overflow: auto;
}

.contact-us--section-aboutt {
    height: auto;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    background-color: white;
    border-radius: 25px;
    position: relative;

    #closeIcon {
        height: 30px;
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
    }

    .contact-us--main-section{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 50px 40px;

        .contact-us--main-header{
            width: 40%;
            text-align: center;
            .contact-us--main-header-title{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                h1 {
                    font-family: 'Poppins-SemiBold', sans-serif;
                    font-size: 1.9rem;
                    color: #222;
                }

                #waving-hand {
                    height: 120px;
                    margin-top: 20px;
                    animation-name: waveHand;
                    animation-duration: 1s;
                    animation-timing-function: linear;
                }

            }
        }

        .contact-us--form{
            height: auto;
            width: 55%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
            background: transparent;
            border-radius: 5px;

            .contact-us--input-label-wrap {
                width: auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                padding: 20px;
                gap: 20px;
            }
            
            #submitForm {
                width: 85%;
                font-size: 1.3rem;
                font-family: 'Poppins', sans-serif;
                background-color: #4A52F7;
                border: 1px solid #4A52F7;
                letter-spacing: 3px;
                color: #fff;
                padding: 15px;
                border-radius: 40px;
                transition: all 0.3s;
                &:hover {
                    cursor: pointer;
                    border: 1px solid #4A52F7;
                    background-color: white;
                    color: #4A52F7;
                }
            }
        }
    }

    #contact-us--footer-outer-section {
        height: 130px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        background-color: #f2f4f7;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        
        #contact-us-footer-inner-section {
            height: inherit;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            font-family: 'Poppins', sans-serif;

            .contact-us-stats-container {
                padding: 15px 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .contact-us-stats-number {
                    font-family: 'Poppins-SemiBold', sans-serif;
                    font-size: 2.6rem;
                }

                .contact-us-stats-header {
                    font-size: 1.8rem;
                    font-family: 'Poppins-SemiBold', sans-serif;
                }

                .contact-us-stats-title {
                    font-size: 1rem;
                    text-align: center;
                }
            }

        }
    }
}

@keyframes waveHand {
    0% {
        transform: rotate(-6deg);
    }

    15% {
        transform: rotate(6deg);
    }

    30% {
        transform: rotate(-6deg);
    }

    45% {
        transform: rotate(6deg);
    }

    65% {
        transform: rotate(-6deg);
    }

    85% {
        transform: rotate(6deg);
    }

    100% {
        transform: rotate(-6deg);
    }   
}


@media only screen and (min-width: 320px) and (max-width: 450px) {
    .contact-us--section-aboutt {
        width: 90%;
        flex-direction: column;

        #closeIcon {
            height: 22px;
            top: 18px;
            right: 15px;
        }

        .contact-us--main-section {
            flex-direction: column;
            justify-content: center;
            padding: 0;

            .contact-us--main-header {
                width: 50%;
                .contact-us--main-header-title {
                    flex-direction: row;
                    padding: 20px 0 0 0;

                    h1 {
                        font-size: 1.1rem;
                    }
    
                    #waving-hand {
                        height: 60px;
                        display: none;
                    }
                }
            }
    
            .contact-us--form {
                width: 95%;
                .contact-us--input-label-wrap {
                    gap: 10px;
                }
    
                #submitForm {
                    font-size: 1rem;
                    width: 85%;
                    margin-bottom: 20px;
                    padding: 10px;
                }   
            }
        }

        #contact-us--footer-outer-section {
            height: 80px;
            #contact-us-footer-inner-section {
                .contact-us-stats-container {
                    padding: 10px 20px;

                    .contact-us-stats-number {
                        font-size: 1.2rem;
                    }
                    
                    .contact-us-stats-header {
                        font-size: 0.8rem;
                    }

                    .contact-us-stats-title {
                        font-size: 0.6rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 450px) and (max-width: 750px) {
    .contact-us--section-aboutt {
        width: 90%;
        flex-direction: column;

        #closeIcon {
            height: 22px;
            top: 18px;
            right: 15px;
        }

        .contact-us--main-section {
            flex-direction: column;
            justify-content: center;
            padding: 0;

            .contact-us--main-header {
                width: 50%;
                .contact-us--main-header-title {
                    flex-direction: row;
                    padding: 20px 0 0 0;

                    h1 {
                        font-size: 1.1rem;
                    }
    
                    #waving-hand {
                        height: 60px;
                        display: none;
                    }
                }
            }
    
            .contact-us--form {
                width: 95%;
                .contact-us--input-label-wrap {
                    gap: 10px;
                }
    
                #submitForm {
                    font-size: 1rem;
                    width: 85%;
                    margin-bottom: 20px;
                    padding: 10px;
                }   
            }
        }

        #contact-us--footer-outer-section {
            height: 80px;
            #contact-us-footer-inner-section {
                .contact-us-stats-container {
                    padding: 10px 25px;

                    .contact-us-stats-number {
                        font-size: 1.3rem;
                    }
                    
                    .contact-us-stats-header {
                        font-size: 0.9rem;
                    }

                    .contact-us-stats-title {
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 750px) and (max-width: 1099px) {
    .contact-us--section-aboutt {
        width: 95%;

        #closeIcon {
            height: 22px;
            top: 18px;
            right: 15px;
        }

        .contact-us--main-section {
            justify-content: center;
            padding: 0;
            .contact-us--main-header {
                width: 25%;
                .contact-us--main-header-title {

                    h1 {
                        font-size: 1.3rem;
                    }
    
                    #waving-hand {
                        height: 90px;
                    }
                }
            }
    
            .contact-us--form {
                width: 60%;
                .contact-us--input-label-wrap {
                    gap: 10px;
                }
    
                #submitForm {
                    font-size: 1rem;
                    width: 85%;
                    margin-bottom: 20px;
                    padding: 10px;
                }   
            }
        }

        #contact-us--footer-outer-section {
            height: 100px;
            #contact-us-footer-inner-section {
                .contact-us-stats-container {
                    .contact-us-stats-number {
                        font-size: 1.9rem;
                    }
                    
                    .contact-us-stats-header {
                        font-size: 1.2rem;
                    }

                    .contact-us-stats-title {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1100px) and (max-width: 1300px) {
    .contact-us--section-aboutt {
        width: 90%;

        .contact-us--main-section {
            padding: 30px 20px;
            justify-content: center;
            .contact-us--main-header {
                .contact-us--main-header-title {

                    h1 {
                        font-size: 1.6rem;
                    }
    
                    #waving-hand {
    
                    }
                }
            }
    
            .contact-us--form {
                width: 60%;
                .contact-us--input-label-wrap {
    
                }
    
                #submitForm {
                    width: 75%;
                }   
            }
        }

        #contact-us--footer-outer-section {
            height: 120px;
            #contact-us-footer-inner-section {
                .contact-us-stats-container {
                    .contact-us-stats-number {
                        font-size: 1.9rem;
                    }
                    
                    .contact-us-stats-header {
                        font-size: 1.5rem;
                    }

                    .contact-us-stats-title {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1600px) {
    .contact-us--section-aboutt {
        .contact-us--main-header {
            
            .contact-us--main-header-title {
                .contact-us--main-header {

                }

                #waving-hand {

                }
            }
        }

        .contact-us--form {
            .contact-us--input-label-wrap {

            }

            #submitForm {

            }
        }

        #contact-us--footer-outer-section {
            #contact-us-footer-inner-section {
                .contact-us-stats-container {
                    .contact-us-stats-number {
                        font-size: 2rem;
                    }
                    
                    .contact-us-stats-header {
                        font-size: 1.5rem;
                    }

                    .contact-us-stats-title {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }

}






















































// @media only screen and (max-width: 1200px) {
//     .contact-us--section-aboutt{
//         flex-flow: wrap;
//         .contact-us--left-section{
//             width: 100%;
//         }
//         .contact-us--right-section{
//             width: 100%;
//         }
//     }
// }
